import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import { FIX_URL } from 'ghost-stories/dist/constants';
import { merge } from 'ramda';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Button,
  Chip,
} from '@material-ui/core';
import useStyles from './styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { descargarReceta } from '../../../redux/actions';

import { getDoctorName } from '../../../helpers/resourceHelpers';
const Row = (props) => {
  const { classes, prescripcion } = props;
  const dispatch = useDispatch();
  const onClick = useCallback(
    () =>
      dispatch(
        descargarReceta(
          Resource.Query({
            [FIX_URL]: prescripcion.id,
          })
        )
      ),
    [dispatch, prescripcion.id]
  );
  return (
    <TableRow className={classes.tableRow} hover>
      <TableCell>
        <div className={classes.nameContainer}>
          <Typography>Dr. {prescripcion.medico}</Typography>
        </div>
      </TableCell>
      <TableCell>
        <Chip
          color={prescripcion.activo ? 'primary' : 'secondary'}
          label={prescripcion.activo ? 'Activo' : 'Inactivo'}
        />
      </TableCell>
      <TableCell>{prescripcion.id}</TableCell>
      <TableCell>
        {moment(prescripcion.fechaPrescripcion).format('DD/MM/YYYY')}
      </TableCell>
      <TableCell>
        <Button color="primary" variant="text" onClick={onClick}>
          <GetAppIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default (props) => {
  const { value, filtro, filtroValue, soloHabilitada } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const filtrarResultados = (prescripcion) => {
    if (filtroValue === '') return true;
    if (filtro !== '') {
      return (prescripcion[filtro].toString() || '')
        .toLowerCase()
        .includes(filtroValue.toLowerCase());
    }
    return Object.values(prescripcion)
      .filter((x) => !!x)
      .map((x) => x.toString())
      .join('')
      .toLowerCase()
      .includes(filtroValue.toLowerCase());
  };

  const filtrarHabilitadas = (prescripcion) =>
    soloHabilitada === prescripcion.activo;

  const table = value
    .map((x) => merge(x, { medico: getDoctorName(x) }))
    .filter(filtrarHabilitadas)
    .filter(filtrarResultados);

  return (
    <Card className={clsx(classes.root)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Medico</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Nro Receta</TableCell>
                <TableCell>Fecha de Prescripción</TableCell>
                <TableCell>Descargar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table
                .slice(page * rowsPerPage, rowsPerPage * (page + 1))
                .map((prescripcion) => (
                  <Row
                    key={prescripcion.id}
                    prescripcion={prescripcion}
                    classes={classes}
                  />
                ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={table.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

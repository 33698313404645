export default {
  ADMIN_LOCALES: '/administrador/locales',
  ADMIN_LOCALES_ADD: '/administrador/locales/agregar',
  ADMIN_LOCALES_EDIT: '/administrador/locales/editar/:sucursalId',
  USER_PROFILE: '/perfil',
  PACIENTE: '/paciente',
  PACIENTE_RECETAS: '/paciente/recetas',
  UTILIDADES_MAPA_FARMACIAS: '/utilidades/farmacias',
  MEDICO: '/medico',
  MEDICO_AGREGAR: '/medico/agregar',
  FARMACEUTICO_DISPENSAR: '/farmaceutico',
  SELECCIONAR_PERFIL: '/seleccionaractividad',
};

import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import { fBuscarPaciente } from '../../../redux/actions';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ReactComponent as UserIcon } from './user.svg';
import { ReactComponent as BirthdayIcon } from './birthday.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import useStyles from '../style';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';

export default () => {
  const [cedula, setCedula] = useState('');
  const [birthday, setBirthday] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const onChangeCedula = useCallback((event) => {
    setCedula(event.target.value);
  }, []);
  const onChangeBirthday = useCallback((event) => {
    setError('');
    setBirthday(event.target.value);
  }, []);

  const onBuscarPaciente = useCallback(
    (event) => {
      event.preventDefault();

      // validate date
      var date = moment(birthday, 'DD/MM/YYYY', true);
      if (date.isValid()) {
        var convertDate = date.format('YYYY-MM-DD');
        window.nroCedula = cedula;
        window.birthdate = convertDate;
        dispatch(
          fBuscarPaciente(
            Resource.Query({
              national_identifier: cedula,
              birthdate: convertDate,
            })
          )
        );
      } else {
        setError('Formato de fecha DD/MM/YYYY');
      }
    },
    [cedula, birthday]
  );
  const clearInputFields = useCallback((event) => {
    setError('');
    setCedula('');
    setBirthday('');
  }, []);

  const searchMessage = useSelector(
    (state) => state.admin.farmaceutico.paciente
  ).matchWith({
    Error: () => (
      <Alert style={{ borderRadius: '10px' }} severity="warning">
        Paciente no encontrado!
      </Alert>
    ),
    Data: () => (
      <Alert severity="success">Paciente encontrado!</Alert>
    ),
    Query: () => <Alert severity="info">Buscando...</Alert>,
    Empty: () => null,
  });

  const classes = useStyles();
  const paciente = useSelector(
    (state) => state.admin.farmaceutico.paciente
  );
  return (
    paciente.value === undefined && (
      <form onSubmit={onBuscarPaciente}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '10%' }}
        >
          <Grid item xs={3}>
            <Card
              elevation={6}
              style={{ borderRadius: '15px', minWidth: '350px' }}
            >
              <CardContent>
                <Typography
                  style={{ color: '#27BEC2' }}
                  gutterBottom
                  variant="h4"
                  component="h2"
                >
                  Identifique al paciente:
                </Typography>
                <Grid
                  style={{ marginTop: '10px' }}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <UserIcon />
                  </Grid>
                  <Grid
                    style={{ paddingLeft: '5px', marginTop: '5px' }}
                    item
                  >
                    Cedula de identidad
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: '5px' }}>
                  <TextField
                    fullWidth
                    // label="Paciente"
                    placeholder="Buscar por Nº de cédula"
                    variant="outlined"
                    value={cedula}
                    onChange={onChangeCedula}
                    required
                  />
                </Grid>
                <Grid
                  style={{ marginTop: '10px' }}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <BirthdayIcon />
                  </Grid>
                  <Grid
                    style={{ paddingLeft: '10px', marginTop: '5px' }}
                    item
                  >
                    Fecha de Nacimiento
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: '10px' }}>
                  <TextField
                    fullWidth
                    // label="Paciente"
                    placeholder="15/09/1987"
                    variant="outlined"
                    value={birthday}
                    onChange={onChangeBirthday}
                    required
                    error={error !== '' ? true : false}
                    helperText={error !== '' ? error : ' '}
                  />
                </Grid>
                <Grid
                  style={{ marginTop: '20px' }}
                  container
                  direction="row"
                  justify="flex-end"
                >
                  <Grid item>
                    <Button
                      className={classes.muiButtonOutlined}
                      variant="outlined"
                      onClick={clearInputFields}
                    >
                      Limpiar
                    </Button>
                  </Grid>
                  <Grid style={{ paddingLeft: '20px' }} item>
                    <Button
                      className={classes.muiButtonContained}
                      type="submit"
                      variant="contained"
                      endIcon={<SearchIcon />}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: '20px' }} item>
                  {searchMessage}
                </Grid>
                {/* <Typography
                  style={{ color: '#DF6D51', marginTop: '20px' }}
                  variant="body2"
                  component="p"
                >
                  Recuerde que el paciente tiene acceso a quién,
                  cuándo y dónde sus datos y recetas fueron
                  visualizados.
                </Typography> */}
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </form>
    )
  );
};

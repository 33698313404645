export const mapPaciente = (data) => {
  const GENDER = {
    male: 'Masculino',
    female: 'Femenino',
    //O: 'Otro',
  };

  return {
    id: data.id,
    nroDocumento: data.identifier,
    nroDocumentoResp: ' ',
    nombre: data.givenName,
    apellido: data.familyName,
    fechaNac: data.birthDate,
    telefono: data.phone,
    email: data.email,
    lugarNacimiento: '-',
    sexo: GENDER[data.gender] || 'Masculino',
    activo: true,
    photoUrl: data.photoUrl,
  };
};

import {
  colors,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';
import typography from './typography';
import { softShadows } from './shadows';
import { esES } from '@material-ui/core/locale';

const themeStyles = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white,
    },
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#717171',
    },
    text: {
      primary: '#000000',
      secondary: '#717171',
    },
  },
  shadows: softShadows,
};

export const createTheme = () =>
  responsiveFontSizes(createMuiTheme(themeStyles, esES));

import { combineEpics } from 'redux-observable';
import administrador from './administrador';
import sucursales from './sucursales';
import personas from './personas';
import paciente from './paciente';
import farmaceutico from './farmaceutico';
import medico from './medico';
import farmacias from './farmacias';

export default combineEpics(
  administrador,
  sucursales,
  personas,
  paciente,
  farmaceutico,
  medico
  // farmacias
);

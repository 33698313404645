import React, { useEffect } from 'react';
import { selectProfile } from '../store/actions';
import { HashRouter as Router, Redirect } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import MainRouter from '../../main/routes';
import { useSelector, useDispatch } from 'react-redux';
import Initializing from './Initializing';

import PROFILES from '../../constants/profiles';

const routes = {
  farmaceutico: '/farmaceutico',
  sinRol: '/',
};

const roles = Object.values(PROFILES);

export const AppRouter = () => {
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();
  const perfil = useSelector((state) => state.main.profile);

  useEffect(() => {
    // if (
    //   initialized &&
    //   keycloak.authenticated &&
    //   keycloak.hasRealmRole('farmaceutico')
    // )
    dispatch(selectProfile(PROFILES.farmaceutico));
  }, [perfil, dispatch, initialized, keycloak]);

  if (!initialized) {
    return <Initializing />;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
  }

  const route = routes[perfil];
  return (
    <Router>
      <Redirect from="/" to={route} />
      <MainRouter />
    </Router>
  );
};

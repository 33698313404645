import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import Keycloak from 'keycloak-js';
import { useDispatch } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { fromResult } from 'ghost-stories/dist/unions/Resource';

import { createTheme } from './theme';
import { AppRouter } from './shared/routes';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';

import { tokenToUser } from './main/redux/state/tokens';
import { almacenarToken } from './main/redux/actions';
import {
  KEYCLOACK_REALM,
  KEYCLOACK_URL,
  KEYCLOACK_SSL,
  KEYCLOACK_CLIENT,
} from './constants/vars';

import './App.css';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

//new iHub
const keycloak = new Keycloak({
  realm: KEYCLOACK_REALM,
  url: KEYCLOACK_URL,
  'ssl-required': KEYCLOACK_SSL,
  clientId: KEYCLOACK_CLIENT,
});

const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
};

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
);

export default function App() {
  useStyles();
  const dispatch = useDispatch();

  const onKeycloakTokens = (tokens) => {
    const payload = fromResult({}, tokens).map(tokenToUser);

    dispatch(almacenarToken(payload));
  };
  return (
    <ThemeProvider theme={createTheme()}>
      <StylesProvider jss={jss}>
        <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={keycloakProviderInitConfig}
          onTokens={onKeycloakTokens}
        >
          <AppRouter />
        </ReactKeycloakProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

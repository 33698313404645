import React from 'react';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import data from '../../../../constants/ciudadesParaguay.json';
import { MakeOptions } from '../../../helpers/formHelpers';

export default (props) => {
  const { values, setProperty, setActive } = props;

  return (
    <form>
      <Card>
        <CardHeader title="Datos de la Sucursal" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                value={values.nombre}
                onChange={setProperty('nombre')}
                label="Nombre de la sucursal"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                value={values.responsable}
                onChange={setProperty('responsable')}
                label="Responsable"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Cédula de identidad"
                value={values.ci_responsable}
                onChange={setProperty('ci_responsable')}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Ciudad"
                SelectProps={{ native: true, value: values.ciudad }}
                variant="outlined"
                onChange={setProperty('ciudad')}
                select
              >
                <MakeOptions options={data} />
              </TextField>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                value={values.direccion}
                onChange={setProperty('direccion')}
                fullWidth
                label="Dirección"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                value={values.telefono}
                onChange={setProperty('telefono')}
                label="Teléfono"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                value={values.email}
                onChange={setProperty('email')}
                label="Correo electrónico de Sucursal"
                required
                type="email"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                checked={values.active}
                onChange={setActive}
                control={<Switch color="primary" />}
                label="Sucursal Habilitada"
                labelPlacement="end"
              />
            </Grid>
            <Grid item md={6} xs={12}></Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

import React, { useState, useCallback } from 'react';
import { toPairs, merge } from 'remeda';
export const MakeOptions = (props) => {
  const { options } = props;
  return toPairs(options).map(([code, value]) => (
    <option key={code} value={code}>
      {value}
    </option>
  ));
};
export const useFormData = (initialData) => {
  const [values, setValues] = useState(initialData);
  const useProperty = (name) =>
    useCallback(
      (e) => {
        const value = e.target.value;
        setValues((formData) => merge(formData, { [name]: value }));
      },
      [name]
    );
  return [values, useProperty];
};

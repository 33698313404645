// ADMINISTRADOR
export const SUCURSALES_RESOURCE = 'ADMINISTRADOR/SUCURSALES_RESOURCE';
export const ALMACENAR_TOKENS = 'ADMINSITRADOR/ALMACENAR_TOKENS';
export const USUARIO_RESOURCE = 'ADMINISTRADOR/USUARIO_RESOURCE';
export const CREAR_SUCURSAL = 'ADMINISTRADOR/SUCURSAL_CREAR';
export const EDITAR_SUCURSAL = 'ADMINISTRADOR/SUCURSAL_EDITAR';

// PROFILE
export const EDITAR_USER_CONTACT = 'PERSONA/EDITAR_USER_CONTACT';
export const EDITAR_USER_PERSONAL = 'PERSONA/EDITAR_USER_PERSONAL';
export const EDITAR_USER_CORPORATE = 'PERSONA/EDITAR_USER_CORPORATE';
export const EDITAR_USER_SECURITY = 'PERSONA/EDITAR_USER_SECURITY';
export const PERSONA_DIRECCION_REVERSE = 'PERSONA/DIRECCION_REVERSE';
export const EDITAR_USER_PORTADA = 'PERSONA/EDITAR_USER_PORTADA';
export const EDITAR_USER_PROFILE = 'PERSONA/EDITAR_USER_PROFILE';
export const PERSONA_RESOURCE = 'PERSONA/PERSONA_RESOURCE';

// PACIENTE
export const PACIENTE_SUCURSALES = 'PACIENTE/SUCURSALES_RESOURCE';
export const PACIENTE_PRESCRIPCION_DETALLE = 'PACIENTE/PRECRIPCION_DETALLE_RESOURCE';
export const PACIENTE_PRESCRIPCIONES = 'PACIENTE/PRECRIPCIONES_RESOURCE';
export const PACIENTE_DESCARGAR_RECETA = 'PACIENTE/DESACARGAR_RECETA';
export const PACIENTE_AGREGAR_RECETA = 'PACIENTE/AGREGAR_RECETA';

// FARMACEUTICO
export const F_BUSCAR_PACIENTE = 'FARMACEUTICO/BUSCAR_PACIENTE';
export const F_BUSCAR_MEDICAMENTOS = 'FARMACEUTICO/BUSCAR_MEDICAMENTOS';
export const F_PROCESAR_SELECCION = 'FARMACEUTICO/PROCESAR_SELECCION_DISPENSAR';
export const F_DISPENSAR_SELECCION = 'FARMACEUTICO/DISPENSAR_SELECCION';

// MEDICO
export const M_LISTAR_PRESCRIPCIONES = 'MEDICO/LISTAR_PRESCRIPCIONES';
export const M_BUSCAR_PACIENTE = 'MEDICO/BUSCAR_PACIENTE';
export const M_BUSCAR_MEDICAMENTOS = 'MEDICO/BUSCAR_MEDICAMENTO';
export const M_SELECCIONAR_MEDICAMENTOS = 'MEDICO/SELECCIONAR_MEDICAMENTO';
export const M_ACTUALIZAR_POSOLOGIA = 'MEDICO/ACTUALIZAR_POSOLOGIA';

export const M_AGREGAR_PACIENTE = 'MEDICO/AGREGAR_PACIENTE';
export const M_AGREGAR_RECETA = 'MEDICO/AGREGAR_RECETA';
export const M_ELIMINAR_POSOLOGIA = 'MEDICO/ELIMINAR_POSOLOGIA';
export const M_ACTUALIZAR_DIAGNOSTICO = 'MEDICO/M_ACTUALIZAR_DIAGNOSTICO';
export const M_ACTUALIZAR_INDICACION = 'MEDICO/M_ACTUALIZAR_INDICACION';
export const M_SOLICITAR_CODIGO = 'MEDICO/M_SOLICITAR_CODIGO';
export const M_VERIFICAR_CODIGO = 'MEDICO/M_VERIFICAR_CODIGO';

// TODO: incorporar busqueda de pacientes de identificaciones
export const M_BUSCAR_PACIENTE_RME = 'MEDICO/BUSCAR_PACIENTE_RME';
export const M_BUSCAR_PACIENTE_MDI = 'MEDICO/BUSCAR_PACIENTE_MDI';
export const M_DATOS_CONTACTO = 'MEDICO/DATOS_PACIENTE';
export const M_CREAR_PACIENTE = 'MEDICO/CREAR_PACIENTE';
export const M_PRESCRIPCION_PRECHECK = 'MEDICO/PRESCRIPCION_PRECHECK';
export const M_PRESCRIPCION_PREPARAR = 'MEDICO/PRESCRIPCION_PREPARAR';
export const M_PREPARAR_PACIENTE = 'MEDICO/PREPARAR_PACIENTE';
export const LIMPIAR_FORMULARIOS = 'SIGNAL/LIMPIAR_FORMULARIO';
export const LIMPIAR_PRESCRIPCIONES = 'SIGNAL/LIMPIAR_PRESCRIPCIONES';
export const MOSTRAR_MENSAJE = 'SIGNAL/MOSTRAR_MENSAJE';
export const REFRESCAR_PANTALLA = 'SIGNAL/REFRESCAR_PANTALLA';
export const M_DUPLICAR_PRESCRIPCION = 'MEDICO/DUPLICAR_PRESCRIPCION';

//FARMACIAS
export const FARMACIAS = 'ADMINISTRADOR/FARMACIAS';

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
}));

export default (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          variant="body1"
          color="inherit"
          to="/paciente"
          component={RouterLink}
        >
          Inicio
        </Link>
        <Link
          variant="body1"
          color="inherit"
          to="/paciente/recetas"
          component={RouterLink}
        >
          Recetas
        </Link>
      </Breadcrumbs>
      <Typography variant="h3" color="textPrimary">
        Historial de Recetas
      </Typography>
    </div>
  );
};

import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default (props) => {
  const { soloHabilitada, setSoloHabilitada } = props;
  return (
    <FormGroup aria-label="filtrar-sucursales-habilitadas">
      <FormControlLabel
        checked={soloHabilitada}
        onChange={(e) => setSoloHabilitada(!soloHabilitada)}
        control={<Switch color="primary" />}
        label="Sucursales Habilitadas"
        labelPlacement="end"
      />
    </FormGroup>
  );
};

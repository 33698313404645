import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';
import Header from './Header';
import DatosFarmacia from './DatosFarmacia';
import DatosDeContacto from './DatosDeContacto';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function AccountView() {
  const { keycloak } = useKeycloak();

  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState('datosFarmacia');
  const tabs = [
    {
      roles: ['farmaceutico'],
      value: 'datosFarmacia',
      label: 'Datos de la Farmacia',
    },
    {
      roles: ['administradorDeLocales', 'paciente', 'farmaceutico'],
      value: 'datosDeContacto',
      label: 'Datos de Contacto',
    },
  ];

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Container maxWidth="xl">
      <Header />
      <Box mt={3}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
          className={classes.tabs}
        >
          {tabs
            .filter((tab) => tab.roles.some(keycloak.hasRealmRole))
            .map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
        </Tabs>
      </Box>
      <Divider />
      <Box mt={3}>
        {currentTab === 'datosFarmacia' && <DatosFarmacia />}
        {currentTab === 'datosDeContacto' && <DatosDeContacto />}
      </Box>
    </Container>
  );
}

export default AccountView;

import moment from 'moment';
import { getDoctorName } from '../../helpers/resourceHelpers';
export const mapMedicamentos = (data) => {
  // console.log(data);
  return data.map((prescripcion) => ({
    numero: prescripcion.medicationId,
    medicamento: prescripcion.medicationName,
    instrucciones: prescripcion.instructions,
    id: prescripcion.id,
    dispensado: prescripcion.status,
    forma: prescripcion.medicationDto.form,
    detalle: prescripcion.medicationDto.form,
    fabricante: prescripcion.medicationDto.manufacturer,
  }));
};

// export const mapDispensarSeleccion = (data, sub) => {
//   return data.map((prescripcion) => {
//     return {
//       usuMod: sub,
//       id: prescripcion.numero,
//       prescripcionDetDTO: [
//         { id: prescripcion.id, dispensado: true },
//       ],
//     };
//   });
// };
export const mapDispensarSeleccion = (data, sub) => {
  return data.map((prescripcion) => {
    return {
      id: prescripcion.id,
      id_healthcareService: sub,
      estado: true,
    };
  });
};

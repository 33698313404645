import React from 'react';

export default () => {
  return (
    <div
      className="container"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 500,
      }}
    >
      <div className="col">
        <div
          className="row d-flex justify-content-center"
          style={{ marginBottom: 20 }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <p style={{ textAlign: 'center', color: '#193F6E' }}>
            {' '}
            CARGANDO...{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

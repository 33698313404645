import { MOSTRAR_MENSAJE } from '../actionsTypes';

const initialState = {
  message: '',
  severity: '',
  open: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MOSTRAR_MENSAJE:
      return action.payload;
    default:
      return state;
  }
};

import React from 'react';
import { Grid } from '@material-ui/core';
import Map from './Map';

export default () => {
  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Map />
      </Grid>
    </Grid>
  );
};

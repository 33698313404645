import { Resource } from 'ghost-stories/dist/unions/Resource';
import { merge } from 'remeda';

import {
  SUCURSALES_RESOURCE,
  CREAR_SUCURSAL,
  EDITAR_SUCURSAL,
} from '../actionsTypes';
import { fromFHIRArray } from '../state/sucursales';

const initialState = {
  lista: Resource.Empty({}),
  agregando: Resource.Empty({}),
  editando: Resource.Empty({}),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUCURSALES_RESOURCE:
      return merge(state, {
        lista: action.payload.map(fromFHIRArray),
      });
    case CREAR_SUCURSAL:
      return merge(state, {
        agregando: action.payload,
      });
    case EDITAR_SUCURSAL:
      return merge(state, {
        editando: action.payload,
      });
    default:
      return state;
  }
};

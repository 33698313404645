import { FIX_URL } from 'ghost-stories/dist/constants';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import { map, tap } from 'rxjs/operators';

import { combineEpics, ofType } from 'redux-observable';

import { ALMACENAR_TOKENS, USUARIO_RESOURCE } from '../actionsTypes';
import { actualizarUsuario } from '../actions';
import {
  toParams,
  makeRequest,
} from 'ghost-stories/dist/streams/resource';
import { authFromState } from '../api/streams';
import { obtenerUsuario } from '../api';

const solicitarUsuario = (action$, state$) =>
  action$.pipe(
    ofType(ALMACENAR_TOKENS),
    map((action) =>
      action.payload
        .map((value) => value.parsed.id.id_healthcareService)
        .chain((resource) =>
          Resource.Query({ [FIX_URL]: resource.value })
        )
    ),
    map(actualizarUsuario)
  );

const procesarUsuario = (action$, state$) =>
  action$.pipe(
    ofType(USUARIO_RESOURCE),
    toParams,
    authFromState(state$),
    makeRequest(obtenerUsuario),
    map(actualizarUsuario)
  );
export default combineEpics(solicitarUsuario);

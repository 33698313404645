import { ofType, combineEpics } from 'redux-observable';
import { map, filter } from 'rxjs/operators';
import { success } from '../actions';

import {
  toParams,
  makeRequest,
} from 'ghost-stories/dist/streams/resource';

import { FIX_URL } from 'ghost-stories/dist/constants';

import { Resource } from 'ghost-stories/dist/unions/Resource';

import {
  EDITAR_USER_CONTACT,
  EDITAR_USER_PERSONAL,
  EDITAR_USER_CORPORATE,
  EDITAR_USER_SECURITY,
  EDITAR_USER_PROFILE,
  EDITAR_USER_PORTADA,
  PERSONA_RESOURCE,
  USUARIO_RESOURCE,
  PERSONA_DIRECCION_REVERSE,
} from '../actionsTypes';

import {
  editarUsuarioContacto,
  editarUsuarioPersonal,
  editarUsuarioCorporate,
  editarUsuarioSecurity,
  editarUsuarioProfile,
  editarUsuarioPortada,
  actualizarPersona,
  actualizarDireccion,
} from '../actions';

import { authFromState } from '../api/streams';

import {
  modificarPersona,
  modificarPassword,
  subirFotoPerfil,
  subirFotoPortada,
  obtenerPersona,
  obtenerDireccion,
} from '../api/index';

const putPersonaContacto = (action$, state$) =>
  action$.pipe(
    ofType(EDITAR_USER_CONTACT),
    toParams,
    authFromState(state$),
    makeRequest(modificarPersona),
    map(editarUsuarioContacto)
  );

const putPersonaCorporate = (action$, state$) =>
  action$.pipe(
    ofType(EDITAR_USER_CORPORATE),
    toParams,
    authFromState(state$),
    makeRequest(modificarPersona),
    map(editarUsuarioCorporate)
  );

const putPersonaPersonal = (action$, state$) =>
  action$.pipe(
    ofType(EDITAR_USER_PERSONAL),
    toParams,
    authFromState(state$),
    makeRequest(modificarPersona),
    map(editarUsuarioPersonal)
  );

const putPersonaPassword = (action$, state$) =>
  action$.pipe(
    ofType(EDITAR_USER_SECURITY),
    toParams,
    authFromState(state$),
    makeRequest(modificarPassword),
    map(editarUsuarioSecurity)
  );

const uploadPersonaAvatar = (action$, state$) =>
  action$.pipe(
    ofType(EDITAR_USER_PROFILE),
    toParams,
    authFromState(state$),
    makeRequest(subirFotoPerfil),
    map(editarUsuarioProfile)
  );

const uploadPersonaPortada = (action$, state$) =>
  action$.pipe(
    ofType(EDITAR_USER_PORTADA),
    toParams,
    authFromState(state$),
    makeRequest(subirFotoPortada),
    map(editarUsuarioPortada)
  );

const toastPersonaSuccess = (action$, state$) =>
  action$.pipe(
    ofType(
      EDITAR_USER_CONTACT,
      EDITAR_USER_PERSONAL,
      EDITAR_USER_CORPORATE
    ),
    filter((action) => Resource.isData(action.payload)),
    map(() => success('Tus datos se han guardado exitosamente.'))
  );

const toastPersonaPassword = (action$, state$) =>
  action$.pipe(
    ofType(EDITAR_USER_SECURITY),
    filter(
      (action) =>
        Resource.isData(action.payload) ||
        Resource.isError(action.payload)
    ),
    map(() => success('Tus datos se han guardado exitosamente.'))
  );

const solicitarPersona = (action$, state$) =>
  action$.pipe(
    ofType(USUARIO_RESOURCE),
    filter((action) => Resource.isData(action.payload)),
    map((action) => action.payload),
    map(actualizarPersona)
  );

const procesarPersona = (action$, state$) =>
  action$.pipe(
    ofType(PERSONA_RESOURCE),
    toParams,
    authFromState(state$),
    makeRequest(obtenerPersona),
    map(actualizarPersona)
  );

const direccionReverse = (action$, state$) =>
  action$.pipe(
    ofType(PERSONA_DIRECCION_REVERSE),
    toParams,
    makeRequest(obtenerDireccion),
    map(actualizarDireccion)
  );

export default combineEpics(
  putPersonaContacto,
  putPersonaCorporate,
  putPersonaPersonal,
  putPersonaPassword,
  toastPersonaSuccess,
  uploadPersonaPortada,
  uploadPersonaAvatar,
  toastPersonaPassword,
  solicitarPersona,
  procesarPersona,
  direccionReverse
);

import { from } from 'rxjs';
import {
  map,
  filter,
  flatMap,
  withLatestFrom,
  ignoreElements,
  mapTo,
  tap,
} from 'rxjs/operators';

import { mergeDeepRight } from 'ramda';
import { combineEpics, ofType } from 'redux-observable';
import { Resource } from 'ghost-stories/dist/unions/Resource';

import {
  F_BUSCAR_PACIENTE,
  F_BUSCAR_MEDICAMENTOS,
  F_PROCESAR_SELECCION,
  F_DISPENSAR_SELECCION,
} from '../actionsTypes';

import {
  fBuscarPaciente,
  dispensarSeleccion,
  fBuscarMedicamentos,
  limpiarFormulario,
  success,
  refrescarPantalla,
  limpiarPrescripciones,
} from '../actions';

import {
  toParams,
  makeRequest,
  makeRequestRaw,
} from 'ghost-stories/dist/streams/resource';

import { authFromState } from '../api/streams';

import {
  postDispensar,
  obtenerPaciente,
  obtenerFarmaceuticoPrescripciones,
} from '../api';

import { mapPaciente } from '../state/paciente';
import {
  mapDispensarSeleccion,
  mapMedicamentos,
} from '../state/receta';

const solicitarPaciente = (action$, state$) =>
  action$.pipe(
    ofType(F_BUSCAR_PACIENTE),
    toParams,
    authFromState(state$),
    makeRequest(obtenerPaciente),
    map((resource) => resource.map(mapPaciente)),
    map(fBuscarPaciente)
  );

const triggerMedicamentos = (action$, state$) =>
  action$.pipe(
    ofType(F_BUSCAR_PACIENTE),
    map((x) => x.payload),
    filter((x) => Resource.isData(x)),
    map((x) =>
      Resource.Query({
        national_identifier: x.value.nroDocumento,
        birthdate: x.value.fechaNac,
        status: 'active',
        count: 200,
        offset: 0,
      })
    ),
    map(fBuscarMedicamentos)
  );

const solicitarMedicamentos = (action$, state$) =>
  action$.pipe(
    ofType(F_BUSCAR_MEDICAMENTOS),
    toParams,
    authFromState(state$),
    makeRequest(obtenerFarmaceuticoPrescripciones),
    map((resource) => resource.map(mapMedicamentos)),
    map(fBuscarMedicamentos)
  );

const solicitarDispensar = (action$, state$) => {
  return action$.pipe(
    ofType(F_PROCESAR_SELECCION),
    toParams,
    authFromState(state$),
    map((request) =>
      mergeDeepRight(request, {
        options: { headers: { accept: 'text/plain' } },
      })
    ),
    makeRequestRaw(postDispensar),
    map(dispensarSeleccion)
  );
};

const triggerLimpiarFormulario = (action$, state$) =>
  action$.pipe(
    ofType(F_DISPENSAR_SELECCION),
    filter((action) => Resource.isData(action.payload)),
    mapTo(true),
    map(limpiarPrescripciones)
  );

const onDispensarSuccess = (action$, state$) =>
  action$.pipe(
    ofType(F_DISPENSAR_SELECCION),
    filter((action) => Resource.isData(action.payload)),
    flatMap(() =>
      from([
        success('Medicamento dispensado con éxito'),

        // map(triggerMedicamentos),
        // refrescarPantalla(),
        //improve this call
        fBuscarMedicamentos(
          Resource.Query({
            national_identifier: window.nroCedula,
            birthdate: window.birthdate,
            status: 'active',
            count: 200,
            offset: 0,
          })
        ),
      ])
    )
  );

export default combineEpics(
  solicitarPaciente,
  triggerMedicamentos,
  solicitarMedicamentos,
  // transformarSeleccionDispensar,
  solicitarDispensar,
  triggerLimpiarFormulario,
  onDispensarSuccess
);

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';

function Formulario() {
  const user = useSelector((state) =>
    state.admin.tokens.tokenInfo.map((x) => x.parsed.id)
  ).getDataOr({
    family_name: 'Red no identificada',
    given_name: 'Sucursal no identificada',
    preferred_username: 'Sin RUC',
  });
  const datos = useSelector(
    (state) => state.admin.tokens.userInfo
  ).getDataOr({});

  var x = JSON.parse(JSON.stringify(datos.organization));

  const lista = useSelector((state) => state.admin.farmacias.lista);
  const farmacia = lista.value.find((x) => x.id === datos.id);

  return (
    <Card>
      <CardHeader title="Datos de la Farmacia" />
      <Divider variant="middle" />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <TextField
              title="Estos datos son de solo lectura"
              fullWidth
              label="Red Farmaceutica"
              name="Nombre"
              type="text"
              variant="outlined"
              value={x.name}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              title="Estos datos son de solo lectura"
              fullWidth
              label="Sucursal"
              name="Nombre de la sucursal"
              type="text"
              variant="outlined"
              value={farmacia.nombre}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              title="Estos datos son de solo lectura"
              fullWidth
              label="RUC"
              name="documento"
              variant="outlined"
              value={farmacia.ruc}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              title="Estos datos son de solo lectura"
              label="Número de Habilitación"
              variant="outlined"
              value={farmacia.habilitacionNro}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              title="Estos datos son de solo lectura"
              fullWidth
              label="CI del regente"
              value={farmacia.regente}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            ></TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              title="Estos datos son de solo lectura"
              fullWidth
              label="CI del responsable"
              value={farmacia.cedula}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            ></TextField>
          </Grid>
        </Grid>
      </CardContent>
      <Divider variant="middle" />
    </Card>
  );
}

export default Formulario;

import { merge } from 'remeda';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import { FARMACIAS } from '../actionsTypes';

const initial = {
  lista: Resource.Empty({}),
};

export default (state = initial, action) => {
  switch (action.type) {
    case FARMACIAS:
      return merge(state, { lista: action.payload });
    default:
      return state;
  }
};

import React, { useState, useEffect, useCallback } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { debounceTime } from 'rxjs/operators';
import ResourceRender from 'ghost-stories/dist/react/resource';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Box,
  Button,
} from '@material-ui/core';
import { merge } from 'remeda';

import {
  editarUsuarioContacto,
  actualizarDireccion,
} from '../../../redux/actions';
import { useStream } from '../../../helpers/useStream';
import { Alert } from '@material-ui/lab';

export default () => {
  const { editando, direccion } = useSelector((state) => ({
    editando: state.admin.personas.editando.contacto,
    direccion: state.admin.personas.direccion,
  }));

  const editandoStream = useStream();
  const addressStream = useStream();

  const initialCenter = [
    editando.params.position.latitude,
    editando.params.position.longitude,
  ];

  const [markerCenter, setMarkerCenter] = useState(initialCenter);
  const [mapCenter] = useState(initialCenter);
  const dispatch = useDispatch();

  // Actualizar el formulario de información de contacto después
  // de que el usuario haya dejado de mover la ubicación por 1 segundo
  useEffect(() => {
    if (editandoStream) {
      editandoStream
        .pipe(debounceTime(1000))
        .subscribe((payload) => {
          dispatch(payload);
        });
    }
  }, [editandoStream, dispatch]);

  // Consultar al api de OSM después de que el usuario haya dejado
  // de seleccionar la ubicación por lo menos por 2 segundos
  // para reducir este tiempo es necesario implementar un servidor
  // de caching
  useEffect(() => {
    if (addressStream) {
      addressStream.pipe(debounceTime(2000)).subscribe((payload) => {
        dispatch(payload);
      });
    }
  }, [addressStream, dispatch]);
  // Consultar al api OSM al montar la página
  useEffect(() => {
    const [lat, lon] = initialCenter;
    dispatch(
      actualizarDireccion(
        direccion.update({
          lat,
          lon,
          format: 'json',
        })
      )
    );
  }, []);

  return (
    <Card>
      <CardHeader title="Selecciona la Ubicación" />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Map
              className="map-container"
              center={mapCenter}
              zoom={16}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={markerCenter} />
            </Map>
          </Grid>
        </Grid>
      </CardContent>
      <Divider variant="middle" />
      <Box p={2} display="flex" justifyContent="flex-start">
        <ResourceRender
          resource={direccion}
          Data={({ value }) => (
            <div>
              <span>
                <p>{value.display_name}</p>
              </span>
            </div>
          )}
          Query={() => <Alert severity="info">Cargando...</Alert>}
          Error={() => (
            <div>
              <p>Hubo un error al obtener la dirección</p>
            </div>
          )}
          Empty={() => null}
        />
      </Box>
    </Card>
  );
};

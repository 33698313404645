import { merge } from 'remeda';
import { Resource } from 'ghost-stories/dist/unions/Resource';

import {
  F_BUSCAR_PACIENTE,
  F_BUSCAR_MEDICAMENTOS,
  LIMPIAR_FORMULARIOS,
  LIMPIAR_PRESCRIPCIONES,
} from '../actionsTypes';

const initial = {
  paciente: Resource.Empty({ nroCedula: '' }),
  medicamentos: Resource.Empty({ nroCedula: '' }),
};

export default (state = initial, action) => {
  switch (action.type) {
    case F_BUSCAR_PACIENTE:
      return merge(state, { paciente: action.payload });
    case F_BUSCAR_MEDICAMENTOS:
      return merge(state, { medicamentos: action.payload });
    case LIMPIAR_FORMULARIOS:
      return initial;
    case LIMPIAR_PRESCRIPCIONES:
      return merge(state, { medicamentos: '' });
    default:
      return state;
  }
};

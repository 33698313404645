import { pipe } from 'rxjs';
import { withLatestFrom, map } from 'rxjs/operators';
import { mergeAll } from 'remeda';

export const authFromState = (state$) =>
  pipe(
    withLatestFrom(
      state$.pipe(
        map((state) => {
          const { tokenInfo } = state.admin.tokens;
          const token = tokenInfo
            .map((value) => value.tokens.token)
            .getDataOr('');
          return {
            options: {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          };
        })
      )
    ),
    map(mergeAll)
  );

import PropTypes from 'prop-types';
import React from 'react';
//import clsx from 'clsx';
//import NavBarVErsion from '../NavBarVersion';
//import { ReactComponent as PuntoFarma } from './puntofarma.svg';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';

import {
  Avatar,
  AppBar,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.light,
  },
  toolbar: {
    minHeight: 64,
    height: '20%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  avatar: {
    width: '35px',
    height: '35px',
  },
}));

const useStylesAvatar = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const classesAvatar = useStylesAvatar();
  const user = useSelector((state) => state.admin.tokens.tokenInfo);
  const fullName = user
    .map((x) => x.parsed.id)
    .map(
      ({ family_name, given_name }) => `${given_name} ${family_name}`
    )
    .getDataOr('Usuario sin Nombre');

  const initialName = user
    .map((x) => x.parsed.id)
    .map(
      ({ family_name, given_name }) =>
        `${given_name[0]}${family_name[0]}`
    )
    .getDataOr('UN');

  return (
    <AppBar color="transparent" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            // className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>

        <Grid style={{ position: 'fixed', right: '0' }}>
          <Grid
            container
            style={{ marginLeft: '15px', marginRight: '15px' }}
          >
            {/* <Avatar
              className={classes.avatar}
              alt="User"
              // key={imgKey}
              // src={
              //   'https://electronicssoftware.net/wp-content/uploads/user.png'
              // }
            /> */}
            {/* <PuntoFarma /> */}
            <Avatar
              className={classesAvatar.orange}
              title={fullName}
            >
              {initialName}
            </Avatar>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;

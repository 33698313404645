import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { FIX_URL } from 'ghost-stories/dist/constants';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import {
  BASE_URL,
  PICTURE_ENDPOINT,
} from '../../../../constants/vars';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  Divider,
} from '@material-ui/core';

import { editarUsuarioProfile } from '../../../redux/actions';
import { randomString } from 'remeda';

const useStyles = makeStyles((theme) => ({
  root: {},
  name: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    height: 150,
    width: 150,
  },
}));
export const firstFile = (e) => e.target.files.item(0);

function ProfileDetails({ className, ...rest }) {
  const datos = useSelector(
    (state) => state.admin.tokens.userInfo
  ).getDataOr({
    name: 'sin nombre',
  });
  const lista = useSelector((state) => state.admin.farmacias.lista);
  const farmacia = lista.value.find((x) => x.id === datos.id);
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.admin.tokens.tokenInfo);
  const name = user
    .map((x) => x.parsed.id)
    .map(
      ({ family_name, given_name }) => `${given_name} ${family_name}`
    )
    .getDataOr('Usuario Sin Nombre');

  const userId = user
    .map((x) => x.parsed.id.sub)
    .getDataOr('{ userId }');

  // const { imgKey, imgUrl } = useSelector((state) =>
  //   state.admin.personas.profilePic.getDataOr({
  //     imgKey: randomString(5),
  //     imgUrl: `${BASE_URL}/${PICTURE_ENDPOINT}/${userId}/perfil`,
  //   })
  // );

  const onFile = useCallback(
    (event) => {
      // const form = new FormData();
      // form.append('imagen', firstFile(event));
      // form[FIX_URL] = `${userId}/perfil`;
      // const request = Resource.Query(form);
      // dispatch(editarUsuarioProfile(request));
    },
    [userId, dispatch]
  );

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          {/* <Avatar
            key={imgKey}
            className={classes.avatar}
            src={imgUrl}
          /> */}
          <Typography
            className={classes.name}
            gutterBottom
            variant="h3"
            color="textPrimary"
          >
            {farmacia.nombre}
          </Typography>
        </Box>
      </CardContent>
      <Divider variant="middle" />
      <CardActions>
        <Button
          fullWidth
          color="primary"
          component="label"
          variant="contained"
        >
          Cambiar foto
          <input
            accept="image/*"
            type="file"
            onChange={onFile}
            style={{ display: 'none' }}
          />
        </Button>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </CardActions>
    </Card>
  );
}

export default ProfileDetails;

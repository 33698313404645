import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import ResourceRender from 'ghost-stories/dist/react/resource';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { FIX_URL } from 'ghost-stories/dist/constants';
import useStyles from '../style';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchtIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as NoFoundIcon } from './nofound.svg';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
  Checkbox,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  limpiarFormulario,
  procesarSeleccionDispensar,
} from '../../../redux/actions';
import { Alert } from '@material-ui/lab';
import { TextFields } from '@material-ui/icons';
import useWindowDimensions from '../../../helpers/useWindowDimensions';

const Table = (props) => {
  const { value } = props;
  const dispatch = useDispatch();

  const { healthCareService, nombre, apellido } = useSelector(
    (state) => ({
      // healthCareService:
      //   state.admin.tokens.tokenInfo.value.parsed.id
      //     .id_healthcareService,
      nombre: state.admin.farmaceutico.paciente.value.nombre,
      apellido: state.admin.farmaceutico.paciente.value.apellido,
    })
  );

  const [open, setOpen] = React.useState(false);
  const [rowid, setRowId] = useState(0);
  const [medicamento, setMedicamento] = useState('');

  const onDispensar = useCallback(() => {
    dispatch(
      procesarSeleccionDispensar(
        Resource.Query({
          [FIX_URL]: `${rowid}/dispense`,
          prescriptionId: `${rowid}`,
          status: 'completed',
          wasSubstituted: false,
        })
      )
    );
  }, [rowid]);

  const handleClickOpen = (id, medicamento) => {
    setOpen(true);
    setRowId(id);
    setMedicamento(medicamento);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CardContent>
      <MaterialTable
        columns={[
          { title: 'id', field: 'id', hidden: true },
          { title: 'Número de la receta', field: 'numero' },
          { title: 'Medicamento', field: 'medicamento' },
          { title: 'Instrucciones', field: 'instructions' },
          // { title: 'Fecha', field: 'fecha' },
          // { title: 'Médico', field: 'medico' },
          // { title: 'Presentación', field: 'presentacion' },
          // { title: 'Principio Activo', field: 'principio' },
          // { title: 'Forma', field: 'forma' },
          // {
          {
            title: 'Dispensar',
            field: 'internal_action',
            // editable: false,
            render: (row) =>
              row && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() =>
                    handleClickOpen(row.id, row.medicamento)
                  }
                >
                  <BeenhereIcon />
                </Button>
              ),
          },
        ]}
        // data={value}
        data={value.filter((x) => x.dispensado === 'active')}
        options={{
          search: false,
          fixedColumns: true,
          toolbar: false,
          paging: false,
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        rowId={rowid}
        medicamento={medicamento}
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirmar para dispensar'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>¿Estás seguro que desea dispensar</p>
            {medicamento}
            <br /> <br />
            <p>para el paciente</p>
            {nombre} {apellido}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={onDispensar} color="primary" autoFocus>
            Dispensar
          </Button>
        </DialogActions>
      </Dialog>
    </CardContent>
  );
};

export default () => {
  const medicamentos = useSelector(
    (state) => state.admin.farmaceutico.medicamentos
  );
  const paciente = useSelector(
    (state) => state.admin.farmaceutico.paciente
  );
  const dispatch = useDispatch();

  const [rowid, setRowId] = useState(0);

  const onDispensar = useCallback(() => {
    dispatch(
      procesarSeleccionDispensar(
        Resource.Query({
          [FIX_URL]: `${rowid}/dispense`,
          prescriptionId: `${rowid}`,
          status: 'completed',
          wasSubstituted: false,
        })
      )
    );
  }, [rowid]);

  const [value, setValue] = React.useState(1);
  function TabPanel(props: {
    [x: string]: any,
    children: any,
    value: any,
    index: any,
  }) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Typography component={'span'}> {children}</Typography>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
  };
  const classes = useStyles();

  const CardList = ({ medicamento }) => {
    const [checked, setChecked] = useState(false);

    const [checkedState, setCheckedState] = useState(
      new Array(medicamento.length).fill(false)
    );

    const [selectedItem, setSelectedItem] = useState(
      JSON.parse(localStorage.getItem('selection')) || []
    );
    const [open, setOpen] = React.useState(false);

    const ConfirmMedicationList = (selectedItem) => {
      const cardsArray = [];
      medicamento.forEach((element, index) => {
        selectedItem.forEach((item) => {
          if (item.prescriptionId === element.id) {
            let medication = medicamento[index];
            cardsArray.push(
              <Card
                key={medication.id}
                // className="container-fluid"
                style={{
                  // width: '400px',
                  // marginRight: '40px',
                  marginBottom: '40px',
                }}
              >
                <CardContent>
                  <Grid>
                    <Grid medication container>
                      <Checkbox
                        checked={checkedState[index]}
                        onChange={() =>
                          handleOnChange(index, medication)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Grid>
                        <Typography
                          noWrap
                          variant="body2"
                          color="textSecondary"
                          style={{ width: '200px' }}
                        >
                          {medication.forma}
                        </Typography>
                        <Typography
                          noWrap
                          variant="body2"
                          color="textPrimary"
                          style={{ width: '200px' }}
                        >
                          {medication.medicamento}
                        </Typography>
                      </Grid>
                      {/* <Typography
                        style={{
                          marginRight: '0',
                          marginLeft: 'auto',
                          marginBottom: 'auto',
                          backgroundColor: '#48BB78',
                          color: 'white',
                          paddingRight: '10px',
                          paddingLeft: '10px',
                          borderRadius: '5px',
                        }}
                      >
                        -
                      </Typography> */}
                    </Grid>
                    <Grid container justify={'space-between'}>
                      <Typography
                        noWrap
                        style={{
                          marginLeft: '40px',
                          color: '#6B7280',
                          width: '350px',
                        }}
                      >
                        {medication.detalle}
                      </Typography>
                      <Typography
                        style={{ color: '#A0AEC0' }}
                      ></Typography>
                    </Grid>
                    <Grid container justify={'space-between'}>
                      <Typography
                        noWrap
                        style={{
                          marginLeft: '40px',
                          color: '#6B7280',
                          width: '300px',
                        }}
                      >
                        {medication.fabricante.toLowerCase()}
                      </Typography>
                      <Typography
                        style={{ color: '#A0AEC0' }}
                      ></Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          marginLeft: '40px',
                          marginTop: '15px',
                          backgroundColor: '#9FA6B2',
                          color: 'white',
                          paddingRight: '10px',
                          paddingLeft: '10px',
                          borderRadius: '5px',
                          maxWidth: '100px',
                        }}
                      >
                        ID - {medication.numero}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          }
        });
      });

      return <div className="col-12 pr-0">{cardsArray}</div>;
    };

    useEffect(() => {
      const tempArray = new Array(medicamento.length).fill(false);
      medicamento.forEach((element, index) => {
        selectedItem.forEach((item) => {
          if (item.prescriptionId === element.id) {
            tempArray[index] = true;
          }
        });
      });
      setCheckedState(tempArray);
    }, []);

    const goBack = useCallback((event) => {
      dispatch(limpiarFormulario(limpiarFormulario));
    }, []);

    const onDispensar = useCallback(() => {
      localStorage.removeItem('selection');
      dispatch(
        procesarSeleccionDispensar(
          // Resource.Data(selectedItem)
          Resource.Query(selectedItem)
        )
      );
    }, [selectedItem]);

    const handleClickOpen = () => {
      setOpen(true);
      // setRowId(id);
      // setMedicamento(medicamento);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleAllChange = (event) => {
      setChecked(event.target.checked);
      if (checked === true) {
        setCheckedState(Array(medicamento.length).fill(false));
        setSelectedItem([]);
        localStorage.removeItem('selection');
      } else {
        setCheckedState(Array(medicamento.length).fill(true));
        setSelectedItem([]);
        localStorage.removeItem('selection');
        let tempArr = [];
        medicamento.map((item) => {
          tempArr.push({
            prescriptionId: item.id,
            status: 'completed',
            wasSubstituted: false,
          });
        });
        setSelectedItem(tempArr);
        localStorage.setItem('selection', JSON.stringify(tempArr));
      }
    };

    const handleOnChange = (position, item) => {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      let medication = {
        prescriptionId: item.id,
        status: 'completed',
        wasSubstituted: false,
      };
      let exist = selectedItem.some(
        (item) => medication.prescriptionId === item.prescriptionId
      );

      if (exist) {
        localStorage.removeItem('selection');
        //delete
        let newArray = selectedItem.filter(
          (item) => medication.prescriptionId !== item.prescriptionId
        );
        setSelectedItem(newArray);
        localStorage.setItem('selection', JSON.stringify(newArray));
        setChecked(false);
      } else {
        //add
        let newArray = [
          ...selectedItem,
          {
            prescriptionId: item.id,
            status: 'completed',
            wasSubstituted: false,
          },
        ];
        setSelectedItem((selectedItem) => newArray);
        localStorage.setItem('selection', JSON.stringify(newArray));
      }
    };

    if (medicamento !== undefined) {
      const cardsArray = medicamento.map((item, index) => (
        <Card
          key={item.id}
          // className="container-fluid"
          style={{
            // width: '400px',
            // marginRight: '40px',
            marginBottom: '40px',
          }}
        >
          <CardContent>
            <Grid>
              <Grid item container>
                <Checkbox
                  checked={checkedState[index]}
                  onChange={() => handleOnChange(index, item)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />

                <Grid>
                  <Typography
                    noWrap
                    style={{ width: '200px' }}
                    variant="body2"
                    color="textSecondary"
                  >
                    {item.forma}
                  </Typography>
                  <Typography
                    noWrap
                    variant="body2"
                    color="textPrimary"
                    style={{ width: '200px' }}
                  >
                    {item.medicamento}
                  </Typography>
                </Grid>
                {/* <Typography
                  style={{
                    marginRight: '0',
                    marginLeft: 'auto',
                    marginBottom: 'auto',

                    backgroundColor: '#48BB78',
                    color: 'white',
                    paddingRight: '10px',
                    paddingLeft: '10px',
                    borderRadius: '5px',
                  }}
                >
                  -
                </Typography> */}
              </Grid>
              <Grid container justify={'space-between'}>
                <Typography
                  noWrap
                  style={{
                    marginLeft: '40px',
                    color: '#6B7280',
                    width: '350px',
                  }}
                >
                  {item.detalle}
                </Typography>
                <Typography
                  style={{ color: '#A0AEC0' }}
                ></Typography>
              </Grid>
              <Grid container justify={'space-between'}>
                <Typography
                  noWrap
                  style={{
                    marginLeft: '40px',
                    color: '#6B7280',
                    width: '200px',
                  }}
                >
                  {item.fabricante.toLowerCase()}
                </Typography>
                <Typography
                  style={{ color: '#A0AEC0' }}
                ></Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    marginLeft: '40px',
                    marginTop: '15px',
                    backgroundColor: '#9FA6B2',
                    color: 'white',
                    paddingRight: '10px',
                    paddingLeft: '10px',
                    borderRadius: '5px',
                    maxWidth: '100px',
                  }}
                >
                  ID - {item.numero}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ));

      return (
        <div className="row">
          <div className="row col-12 pr-0">
            {cardsArray.map((data, i) =>
              i % 2 ? (
                <div key={i} className="col-md-6 pr-0">
                  {data}
                </div>
              ) : (
                <div key={i} className="col-md-6">
                  {data}
                </div>
              )
            )}
          </div>

          <div className="row col-12 mt-10">
            <div className="row col">
              <Checkbox
                checked={checked}
                onChange={handleAllChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography
                component={'span'}
                style={{
                  marginTop: '10px',
                  color: '#364152',
                }}
              >
                Seleccionar todos
              </Typography>
            </div>

            <div className="row ml-auto">
              <div className="mr-3">
                <Button
                  className={classes.muiButtonOutlined}
                  variant="outlined"
                  onClick={goBack}
                >
                  Cancelar
                </Button>
              </div>
              <div>
                <Button
                  className={classes.muiButtonContained}
                  type="submit"
                  variant="contained"
                  endIcon={<CheckIcon />}
                  onClick={handleClickOpen}
                >
                  Dispensar
                </Button>
              </div>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              // rowId={rowid}
              // medicamento={medicamento}
            >
              <DialogContent>
                <h3 style={{ color: '#0694A2', padding: '11px' }}>
                  Se dispensarán los siguientes medicamentos
                </h3>
                {ConfirmMedicationList(selectedItem)}
              </DialogContent>
              <div
                style={{ margin: '10px', paddingRight: '10px' }}
                className="row ml-auto"
              >
                <div className="mr-3">
                  <Button
                    className={classes.muiButtonOutlined}
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                </div>
                <div>
                  <Button
                    className={classes.muiButtonContained}
                    type="submit"
                    variant="contained"
                    endIcon={<CheckIcon />}
                    onClick={onDispensar}
                  >
                    Dispensar
                  </Button>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      );
    }
    return (
      <Typography component="span">
        <Grid component="span">Cargando ... </Grid>
      </Typography>
    );
  };

  const [medicationFilter, setMedicationFilter] = useState('');

  const [medications, setMedications] = useState([]);

  const onChangeFilter = useCallback((event) => {
    setMedicationFilter(event.target.value);
  }, []);

  useEffect(() => {
    if (medicamentos.value !== undefined) {
      setMedications(medicamentos.value);
      const tempArray = medicamentos.value.filter(
        (item) =>
          item.numero
            .toLowerCase()
            .match(medicationFilter.toLowerCase(), 'g') ||
          item.medicamento
            .toLowerCase()
            .match(medicationFilter.toLowerCase(), 'g') ||
          item.detalle
            .toLowerCase()
            .match(medicationFilter.toLowerCase(), 'g') ||
          item.forma
            .toLowerCase()
            .match(medicationFilter.toLowerCase(), 'g')
      );
      setMedications(tempArray);
    } else {
      setMedications([]);
    }
  }, [medicationFilter, medicamentos]);
  const { _, width: screenWidth } = useWindowDimensions();

  return (
    paciente.value !== undefined && (
      <>
        {paciente.params.nroCedula !== '' && (
          <>
            <Grid>
              <Grid style={{ marginTop: '25px' }}>
                <Typography variant="h3" color="secondary">
                  Medicamentos a dispensar
                </Typography>
                {/* <Typography variant="body2" color="textSecondary">
                  actualizado a la fecha{' '}
                  {new Date().toLocaleString() + ''}
                </Typography> */}
              </Grid>
              <div
                className="row"
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                {/* <div className="col-md-5 col-12 pr-0">
                  <Typography variant="body2" color="textSecondary">
                    <FilterListIcon style={{ fill: 'gray' }} />
                    Mostrar:
                  </Typography>
                  <Tabs
                    classes={{
                      root:
                        screenWidth > 1600
                          ? classes.tabHeight
                          : classes.tabHeightSmall,
                    }}
                    TabIndicatorProps={{
                      style: {
                        background: '#27BEC2',
                        // marginTop: '10px',
                        // marginBottom: '20px',
                        display: 'none',
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab
                      classes={{
                        selected: classes.selected,
                      }}
                      style={{
                        borderStartStartRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        fontWeight: 'bold',
                        fontSize: '15px',
                        borderColor: '#27BEC2',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        // marginTop: '10px',
                      }}
                      label="Controlados"
                      {...a11yProps(1)}
                    />
                    <Tab
                      classes={{
                        selected: classes.selected,
                      }}
                      label="Todos"
                      style={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderColor: '#27BEC2',
                      }}
                      {...a11yProps(1)}
                    />
                    <Tab
                      classes={{
                        selected: classes.selected,
                      }}
                      style={{
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        borderWidth: '2px',
                        borderColor: '#27BEC2',
                        borderStyle: 'solid',
                        fontWeight: 'bold',
                        fontSize: '15px',
                      }}
                      label="Libres"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </div> */}
                <div className="col-md-7 col-12 pl-3">
                  <Typography variant="body2" color="textSecondary">
                    <SearchtIcon style={{ fill: 'gray' }} /> Buscar
                    medicamentos del paciente:
                  </Typography>
                  <TextField
                    fullWidth
                    classes={{
                      root:
                        screenWidth > 1600
                          ? classes.textFieldPadding
                          : classes.textFieldPaddingSmall,
                    }}
                    placeholder="nombre comercial, principio activo, laboratorio o numero de receta."
                    variant="outlined"
                    value={medicationFilter}
                    onChange={onChangeFilter}
                    // required
                  />
                </div>
              </div>
            </Grid>
            <TabPanel value={value} index={0}>
              <></>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {medications.length > 0 ? (
                <CardList medicamento={medications} />
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: '20%' }}
                >
                  <div className="align-middle">
                    <figure className="text-center">
                      <NoFoundIcon style={{ width: '130px' }} />
                      <figcaption
                        style={{
                          textAlign: 'center',
                          marginTop: '10px',
                          color: '#6B7280',
                        }}
                      >
                        No se encontraron medicamentos
                      </figcaption>
                    </figure>
                  </div>

                  {/* <Grid>
                    <Grid>
                      <NoFoundIcon />
                    </Grid>
                    <Grid>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                      >
                        No se encontraron medicamentos
                      </Typography>
                    </Grid>
                  </Grid> */}
                </Box>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <></>
            </TabPanel>
          </>
        )}
      </>
    )
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import configureStore from './shared/store/store';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.Fragment>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

serviceWorker.unregister();

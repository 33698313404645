import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResouceRender from 'ghost-stories/dist/react/resource';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';

import { actualizarPacienteSucursales } from '../../redux/actions';

const Empty = () => null;
const toLatLng = (position) => ({
  lat: position.latitude,
  lng: position.longitude,
});

const MarkerItem = (sucursal) => {
  return (
    <Marker key={sucursal.id} position={toLatLng(sucursal.position)}>
      <Popup>
        <h4>{sucursal.name}</h4>
        <p>{sucursal.address.line.join('')}</p>
        <p>
          Teléfono:
          {sucursal.telecom
            .filter((x) => x.system === 'phone')
            .map((x) => x.value)
            .join(',')}
        </p>
        <p>
          Email:
          {sucursal.telecom
            .filter((x) => x.system === 'email')
            .map((x) => x.value)
            .join(',')}
        </p>
        <p>{sucursal.address.city}</p>
      </Popup>
    </Marker>
  );
};

const MarkerList = (props) => {
  const { value } = props;
  return value.filter((x) => x.active).map(MarkerItem);
};

export default () => {
  const dispatch = useDispatch();
  const sucursales = useSelector(
    (state) => state.admin.paciente.sucursales
  );

  useEffect(() => {
    sucursales.matchWith({
      Empty: () =>
        dispatch(actualizarPacienteSucursales(sucursales.update())),
      Data: () => null,
      Query: () => null,
      Error: () => null,
    });
  }, []);

  const initialCenter = [-25.28207, -57.63539];
  return (
    <Card>
      <CardHeader title="Farmacias adheridas" />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Map
              center={initialCenter}
              zoom={12}
              className="map-container-full"
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <ResouceRender
                resource={sucursales}
                Data={MarkerList}
                Query={Empty}
                Empty={Empty}
                Error={Empty}
              />
            </Map>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

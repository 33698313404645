import React, { useState, useCallback, useEffect } from 'react';

import {
  Card,
  Box,
  CardContent,
  Button,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { useSelector, useDispatch } from 'react-redux';
import { debounceTime } from 'rxjs/operators';
import ResourceRender from 'ghost-stories/dist/react/resource';
import { Resource } from 'ghost-stories/dist/unions/Resource';

import { useStream } from '../../../helpers/useStream';
import { actualizarDireccion } from '../../../redux/actions';
import { Alert } from '@material-ui/lab';

export default (props) => {
  const { position, setPosition, onSubmit, editando } = props;
  const initialCenter = [position.latitude, position.longitude];
  const addressStream = useStream();

  const [markerCenter, setMarkerCenter] = useState(initialCenter);
  const [mapCenter] = useState(initialCenter);
  const { direccion } = useSelector((state) => ({
    direccion: state.admin.personas.direccion,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    if (addressStream) {
      addressStream.pipe(debounceTime(2000)).subscribe((payload) => {
        dispatch(payload);
      });
    }
  }, [addressStream, dispatch]);
  useEffect(() => {
    const [lat, lon] = initialCenter;
    dispatch(
      actualizarDireccion(
        direccion.update({
          lat,
          lon,
          format: 'json',
        })
      )
    );
    // eslint-disable-next-line
  }, []);
  const onMove = useCallback(
    (e) => {
      const { lat, lng } = e.target.getCenter();
      setPosition({ latitud: lat, longitude: lng });
      setMarkerCenter([lat, lng]);
      if (addressStream) {
        addressStream.next(
          actualizarDireccion(
            direccion.update({
              lat,
              lon: lng,
              format: 'json',
            })
          )
        );
      }
    },
    [addressStream, direccion, setPosition]
  );
  const loading = Resource.isQuery(editando);
  return (
    <Card>
      <CardHeader title="Selecciona la Ubicación" />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Map
              className="map-container"
              center={mapCenter}
              zoom={16}
              onmove={onMove}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={markerCenter} />
            </Map>
          </Grid>
        </Grid>
      </CardContent>
      <Divider variant="middle" />
      <Box p={2} display="flex" justifyContent="flex-start">
        <ResourceRender
          resource={direccion}
          Data={({ value }) => (
            <div>
              <span>
                <p>{value.display_name}</p>
              </span>
            </div>
          )}
          Query={() => <Alert severity="info">Cargando...</Alert>}
          Error={() => (
            <div>
              <p>Hubo un error al obtener la dirección</p>
            </div>
          )}
          Empty={() => null}
        />
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          onClick={onSubmit}
          type="submit"
          variant="contained"
          disabled={loading}
        >
          Guardar
        </Button>
      </Box>
    </Card>
  );
};

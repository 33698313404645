import { BASE_URL, PICTURE_ENDPOINT, MEDICO_VALIDACION_URL } from '../../../constants/vars';
import { qsGetter, jsonPoster, jsonPut, bodyPoster } from 'ghost-stories/dist/fetch/helpers';

export const obtenerSucursales = qsGetter(`${BASE_URL}/healthcareservice/organization`);

export const obtenerUsuario = qsGetter(`${BASE_URL}/healthcareservice`);

export const agregarSucursal = jsonPoster(`${BASE_URL}/locales/admin/crearSucursal`);

export const modificarSucursal = jsonPut(`${BASE_URL}/locales/admin/editarSucursal`);

export const modificarPersona = jsonPut(`${BASE_URL}/persona/edit`);

export const obtenerPersona = qsGetter(`${BASE_URL}/persona`);

// get persona info
// la url se elige en el componente por eso va vacía aquí
export const modificarPassword = jsonPoster('');

export const subirFotoPortada = bodyPoster(`${BASE_URL}/${PICTURE_ENDPOINT}`);

export const subirFotoPerfil = bodyPoster(`${BASE_URL}/${PICTURE_ENDPOINT}`);

export const obtenerPacienteSucursales = qsGetter(`${BASE_URL}/healthcareservice`);

export const obtenerPacientePrescripciones = jsonPoster(`${BASE_URL}/prescripcion/searchJoin`);

export const obtenerPacientePrescriponesDetalles = jsonPoster(`${BASE_URL}/prescripcion/searchJoin`);

export const obtenerPacienteIdentificaciones = qsGetter(`https://rme-test.pti.org.py/policia`);

export const obtenerReceta = qsGetter(`${BASE_URL}/prescripcion/pdf`);

export const crearPaciente = jsonPoster(`${BASE_URL}/patient/create`);

export const guardarAgregarReceta = jsonPoster(`${BASE_URL}/userPrescripcion/save`);

export const obtenerDireccion = qsGetter('https://nominatim.openstreetmap.org/reverse');

export const obtenerPaciente = qsGetter(`${BASE_URL}/profile/dispenser/patient`);

// export const postDispensar = jsonPoster(`${BASE_URL}/prescripcion/create`);

export const postDispensar = jsonPoster(`${BASE_URL}/profile/dispenser/prescriptionGroup/dispense`);

export const obtenerMedicoPrescripciones = qsGetter(`${BASE_URL}/profile/dispenser/prescription`);

export const obtenerFarmaceuticoPrescripciones = qsGetter(`${BASE_URL}/profile/dispenser/prescription`);

export const obtenerMedicamentos = jsonPoster(`${BASE_URL}/medicamento/search`);

export const postPrescripcion = jsonPoster(`${BASE_URL}/prescripcion/create`);

export const getAceptarTerminos = qsGetter(`${MEDICO_VALIDACION_URL}/getCode`);

export const getVerificarCodigo = qsGetter(`${MEDICO_VALIDACION_URL}/checkCode`);

//FARMACIAS
export const obtenerFarmacias = qsGetter(`${BASE_URL}/healthcareservice/findAll`);

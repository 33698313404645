import { ALMACENAR_TOKENS, USUARIO_RESOURCE } from '../actionsTypes';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import { merge } from 'remeda';

const initialState = {
  userInfo: Resource.Empty({}),
  tokenInfo: Resource.Empty({}),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ALMACENAR_TOKENS:
      return merge(state, {
        tokenInfo: action.payload,
      });
    case USUARIO_RESOURCE:
      return merge(state, {
        userInfo: action.payload,
      });
    default:
      return state;
  }
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FIX_URL } from 'ghost-stories/dist/constants';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import useStyles from './styles';
import SucursalesList from './SucursalList';
import { actualizarSucursales } from '../../redux/actions';

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const organizationId = useSelector((state) =>
    state.admin.tokens.tokenInfo
      .map((x) => x.parsed.id.id_healthcareService)
      .getDataOr('')
  );
  useEffect(() => {
    dispatch(
      actualizarSucursales(
        Resource.Query({
          [FIX_URL]: organizationId,
        })
      )
    );
  }, [organizationId]);

  return <SucursalesList classes={classes} />;
};

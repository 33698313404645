import { merge } from 'remeda';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import {
  PACIENTE_SUCURSALES,
  PACIENTE_PRESCRIPCION_DETALLE,
  PACIENTE_PRESCRIPCIONES,
  PACIENTE_AGREGAR_RECETA,
} from '../actionsTypes';

const initial = {
  detalles: Resource.Empty({}),
  sucursales: Resource.Empty({}),
  agregarReceta: Resource.Empty({}),
  prescripciones: Resource.Empty({}),
};

export default (state = initial, action) => {
  switch (action.type) {
    case PACIENTE_SUCURSALES:
      return merge(state, { sucursales: action.payload });
    case PACIENTE_PRESCRIPCION_DETALLE:
      return merge(state, { detalles: action.payload });
    case PACIENTE_PRESCRIPCIONES:
      return merge(state, { prescripciones: action.payload });
    case PACIENTE_AGREGAR_RECETA:
      return merge(state, { agregarReceta: action.payload });
    default:
      return state;
  }
};

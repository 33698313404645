import { useState, useEffect } from 'react';
import { Subject } from 'rxjs';
export const useStream = () => {
  let [stream, setStream] = useState(null);
  useEffect(() => {
    setStream(new Subject());
    return () => setStream(null);
  }, []);
  return stream;
};

import React, { useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';

import { PrivateRoute } from '../shared/routes/utils';

import NavBar from './pages/NavBar';
import TopBar from './pages/TopBar';
import Recetas from './pages/ListaRecetas';
import Sucursales from './pages/Sucursales';
import EditarPerfil from './pages/EditarPerfil';
import Farmaceutico from './pages/DashboardFarmaceutico';
import MapaFarmacias from './pages/MapaFarmacias';
import EditarSucursal from './pages/EditarSucursal';
import AgregarSucursal from './pages/AgregarSucursal';
import Snackbar from './components/Snackbar';
import useStyles from './pages/styles';
import ROLES from '../constants/roles';
import ROUTES from '../constants/routes';

export default () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Snackbar />
            <Router>
              <PrivateRoute
                exact
                roles={[ROLES.ADMIN_LOCALES]}
                path={ROUTES.ADMIN_LOCALES}
                component={Sucursales}
              />
              <PrivateRoute
                exact
                roles={[ROLES.ADMIN_LOCALES]}
                path={ROUTES.ADMIN_LOCALES_ADD}
                component={AgregarSucursal}
              />
              <PrivateRoute
                exact
                roles={[ROLES.ADMIN_LOCALES]}
                path={ROUTES.ADMIN_LOCALES_EDIT}
                component={EditarSucursal}
              />
              <PrivateRoute
                exact
                roles={[
                  ROLES.ADMIN_LOCALES,
                  ROLES.PACIENTE,
                  ROLES.FARMACEUTICO,
                  ROLES.MEDICO,
                ]}
                path={ROUTES.USER_PROFILE}
                component={EditarPerfil}
              />

              <PrivateRoute
                exact
                roles={[ROLES.PACIENTE]}
                path={ROUTES.PACIENTE_RECETAS}
                component={Recetas}
              />
              <PrivateRoute
                exact
                roles={[
                  ROLES.ADMIN_LOCALES,
                  ROLES.PACIENTE,
                  ROLES.FARMACEUTICO,
                  ROLES.MEDICO,
                ]}
                path={ROUTES.UTILIDADES_MAPA_FARMACIAS}
                component={MapaFarmacias}
              />

              <PrivateRoute
                exact
                roles={[ROLES.FARMACEUTICO]}
                path={ROUTES.FARMACEUTICO_DISPENSAR}
                component={Farmaceutico}
              />
            </Router>
          </div>
        </div>
      </div>
    </div>
  );
};

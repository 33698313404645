import { toLower } from 'ramda';

const GENDER_MAP = {
  male: 'M',
  female: 'F',
  unknown: 'O',
};
const defaultExtension = { valueString: '' };

const findExtension = (extension, predicate) =>
  extension
    ? extension.find(predicate) || defaultExtension
    : defaultExtension;

export const selectContacto = (persona) => {
  const defaultAddress = {
    city: 'Asuncion',
    line: [],
    country: 'PY',
  };

  const direccion = persona.address
    ? persona.address[0] || defaultAddress
    : defaultAddress;

  const telefono1 = persona.telecom.find(
    (x) => x.system === 'phone' && x.rank === 1
  ) || { value: '', use: 'mobile' };

  const telefono2 = persona.telecom.find(
    (x) => x.system === 'phone' && x.rank === 2
  ) || { value: '', use: 'home' };

  const email1 = persona.telecom.find(
    (x) => x.system === 'email' && x.use === 'home'
  ) || { value: '', use: 'home' };

  return {
    id: persona.id,
    position: persona.position || {
      latitude: -24.52,
      longitude: -55.52,
    },
    ciudad: direccion.city,
    departamento: '',
    pais: direccion.country,
    direccion: direccion.line ? direccion.line.join('') : '',
    telefono1: telefono1.value,
    telefono1Type: telefono1.use,
    telefono2: telefono2.value,
    telefono2Type: telefono2.use,
    email1: email1.value,
  };
};
export const selectCorporativo = (persona) => {
  const telefono3 = persona.telecom.find(
    (x) => x.system === 'phone' && x.rank === 3
  ) || { value: '', use: 'work' };

  const email2 = persona.telecom.find(
    (x) => x.system === 'email' && x.use === 'work'
  ) || { value: '', use: 'work' };

  const cargo = findExtension(
    persona.extension,
    (x) => x.id === 'cargo'
  );

  return {
    id: persona.id,
    telefono3: telefono3.value,
    email2: email2.value,
    cargo: cargo.valueString,
  };
};
export const selectPersonales = (persona) => {
  const name = persona.name.find((x) => x.use === 'official');

  const bloodGroup = findExtension(
    persona.extension,
    (x) => x.id === 'bloodgroup'
  );

  const nationality = findExtension(
    persona.extension,
    (x) => x.id === 'nationality'
  );
  const nacionalidad = nationality.valueString.toLowerCase();
  // .map((nac) => {
  //   return nac.charAt(0).toUpperCase() + nac.slice(1);
  // });
  const documento = persona.identifier.find(
    (x) => x.id === 'cedula' || x.id === 'pasaporte'
  ) || { value: '', id: 'cedula', assigner: { reference: 'PY' } };

  const nombreCase = (name.given ? name.given.join('') : '')
    .toLowerCase()
    .trim()
    .split(' ')
    .map((nameCase) => {
      return nameCase[0].toUpperCase() + nameCase.slice(1);
    })
    .join(' ');

  const apellidoCase = (name.family || '')
    .toLowerCase()
    .split(' ')
    .map((familyCase) => {
      return familyCase[0].toUpperCase() + familyCase.slice(1);
    })
    .join(' ');

  return {
    id: persona.id,
    prefijo: name.prefix ? name.prefix.join('') : '',
    nombre: nombreCase,
    apellido: apellidoCase,
    nacionalidad:
      nacionalidad.charAt(0).toUpperCase() + nacionalidad.slice(1),
    tipoDocumento: documento.id,
    documento: documento.value,
    emisorDocumento: documento.assigner.reference,
    fechaNacimiento: persona.birthDate,
    sexo: GENDER_MAP[persona.gender],
    grupoSanguineo: bloodGroup.valueString,
  };
};

export const addNombreCompleto = (persona) =>
  Object.assign({}, persona, {
    nombreCompleto: `${persona.nombre} ${persona.apellido} `,
  });

export const TITLES = {
  dr: 'Dr.',
  sr: 'Señor',
  sra: 'Señora',
  srita: 'Señorita',
};

export const GENDER = {
  M: 'Masculino',
  F: 'Femenino',
  //O: 'Otro',
};

export const GENDER_min = {
  M: 'male',
  F: 'female',
  O: 'otro',
};

export const OFACTOR = {
  'o+': 'O rh(+)',
  'o-': 'O rh(-)',
  'a+': 'A rh(+)',
  'a-': 'A rh(-)',
  'b+': 'B rh(+)',
  'b-': 'B rh(-)',
  'ab+': 'AB rh(+)',
  'ab-': 'AB rh(-)',
};

export const DOCUMENT_TYPES = {
  cedula: 'Cédula',
  pasaporte: 'Pasaporte',
};
export const PHONE_TYPES = {
  HOME: 'Casa',
  MOBILE: 'Móvil',
  WORK: 'Trabajo',
};
export const fromPolicia = (MDI) => {
  const nombreADTD = MDI.nombres
    .toLowerCase()
    .split(' ')
    .map((name) => {
      return name[0].toUpperCase() + name.slice(1);
    })
    .join(' ');

  const apellidoADTD = MDI.apellido
    .toLowerCase()
    .split(' ')
    .map((family) => {
      return family[0].toUpperCase() + family.slice(1);
    })
    .join(' ');

  return {
    nroDocumento: MDI.cedula.toString(10),
    nombre: nombreADTD,
    apellido: apellidoADTD,
    sexo: GENDER[MDI.sexo],
    fechaNac: MDI.fechaNacim,
    lugarNacimiento: '',
    email: '',
    nacionalidad: 'PY',
    usuAlta: 'ADMIN',
    usuMod: null,
    fechAlta: null,
    fechMod: null,
    activo: 'TRUE',
  };
};

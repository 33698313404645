import { FIX_URL } from 'ghost-stories/dist/constants';
import { from, of } from 'rxjs';
import {
  map,
  flatMap,
  filter,
  mapTo,
  withLatestFrom,
  ignoreElements,
  delay,
} from 'rxjs/operators';
import { Resource } from 'ghost-stories/dist/unions/Resource';

import { combineEpics, ofType } from 'redux-observable';
import { mergeDeepRight, merge } from 'ramda';

import {
  M_LISTAR_PRESCRIPCIONES,
  M_BUSCAR_PACIENTE,
  M_BUSCAR_PACIENTE_RME,
  M_BUSCAR_PACIENTE_MDI,
  M_BUSCAR_MEDICAMENTOS,
  M_AGREGAR_RECETA,
  M_DUPLICAR_PRESCRIPCION,
  M_CREAR_PACIENTE,
  M_SELECCIONAR_MEDICAMENTOS,
  M_PRESCRIPCION_PRECHECK,
  M_PRESCRIPCION_PREPARAR,
  M_PREPARAR_PACIENTE,
  M_SOLICITAR_CODIGO,
  M_VERIFICAR_CODIGO,
  REFRESCAR_PANTALLA,
} from '../actionsTypes';

import {
  mListarPrescripciones,
  mBuscarPaciente,
  mBuscarPacienteRME,
  mBuscarPacienteMDI,
  mBuscarMedicamento,
  mAgregarReceta,
  mCrearPaciente,
  limpiarFormulario,
  success,
  error,
  mPrescripcionPreparar,
  mPrepararPaciente,
  mSolicitarCodigo,
  mVerificarCodigo,
  refrescarPantalla,
} from '../actions';

import {
  toParams,
  makeRequest,
  makeRequestRaw,
} from 'ghost-stories/dist/streams/resource';

import { mapPaciente } from '../state/paciente';
import { fromPolicia } from '../state/personas';
import { authFromState } from '../api/streams';

import {
  obtenerMedicoPrescripciones,
  obtenerMedicamentos,
  obtenerPaciente,
  obtenerPacienteIdentificaciones,
  postPrescripcion,
  crearPaciente,
  getAceptarTerminos,
  getVerificarCodigo,
} from '../api';
import { mapDuplicarPrescripcion } from '../state/medico';

const mapAgregarRecetaRaw = (resource) => {
  const resource2 = resource.chain((response) => {
    if (response.value.status === 200) {
      return Resource.Data(response.value.status, response.params);
    }
    if (response.value.status === 204) {
      return Resource.Empty(response.params);
    }

    return Resource.Empty(response.params);
  });
  return of(resource2);
};

const solicitarCodigoRaw = (resource) => {
  const resource2 = resource.chain((response) => {
    if (response.value.status === 200) {
      return Resource.Data(response.value.status, response.params);
    }
    if (response.value.status === 204) {
      return Resource.Data({ exito: true }, response.params);
    }

    return Resource.Empty(response.params);
  });
  return of(resource2);
};

const verificarCodigoRaw = (resource) => {
  const resource2 = resource.chain((response) => {
    console.log(response.value);
    if (response.value.status === 200) {
      return Resource.Data({ exito: true }, response.params);
    }

    if (response.value.status === 204) {
      return Resource.Data({ exito: true }, response.params);
    }

    if (response.value.status === 400) {
      return Resource.Error(
        ['Código Inválido, favor vuelva a intentar'],
        response.params
      );
    }

    return Resource.Empty(response.params);
  });
  return of(resource2);
};

const verificarCodigoError = (action$, state$) =>
  action$.pipe(
    ofType(M_VERIFICAR_CODIGO),
    filter((action) => Resource.isError(action.payload)),
    map((action) => error(action.payload.messages.join()))
  );

const verificarCodigoExito = (action$, state$) =>
  action$.pipe(
    ofType(M_VERIFICAR_CODIGO),
    filter((action) => Resource.isData(action.payload)),
    flatMap((resource) =>
      from([
        success('Código verificado exitosamente'),
        refrescarPantalla(),
      ])
    )
  );
const verificarCodigoRefrescar = (action$, state$) =>
  action$.pipe(
    ofType(REFRESCAR_PANTALLA),
    delay(1000),
    map(() => {
      window.location.reload();
    }),
    ignoreElements()
  );

const solicitarPrescripciones = (action$, state$) =>
  action$.pipe(
    ofType(M_LISTAR_PRESCRIPCIONES),
    toParams,
    authFromState(state$),
    makeRequest(obtenerMedicoPrescripciones),
    map(mListarPrescripciones)
  );

const solicitarVerificarCodigo = (action$, state$) =>
  action$.pipe(
    ofType(M_VERIFICAR_CODIGO),
    toParams,
    authFromState(state$),
    makeRequestRaw(getVerificarCodigo),
    flatMap(verificarCodigoRaw),
    map(mVerificarCodigo)
  );

const solicitarAceptarCondiciones = (action$, state$) =>
  action$.pipe(
    ofType(M_SOLICITAR_CODIGO),
    toParams,
    authFromState(state$),
    makeRequestRaw(getAceptarTerminos),
    flatMap(solicitarCodigoRaw),
    map(mSolicitarCodigo)
  );

const guardarPrescripcion = (action$, state$) =>
  action$.pipe(
    ofType(M_AGREGAR_RECETA),
    toParams,
    authFromState(state$),
    map((request) =>
      mergeDeepRight(request, {
        options: { headers: { accept: 'text/plain' } },
      })
    ),
    makeRequestRaw(postPrescripcion),
    flatMap(mapAgregarRecetaRaw),
    map(mAgregarReceta)
  );

const solicitarMedicamentos = (action$, state$) =>
  action$.pipe(
    ofType(M_BUSCAR_MEDICAMENTOS),
    filter(
      (action) => action.payload.params.nombreComercial.length > 4
    ),
    toParams,
    authFromState(state$),
    makeRequest(obtenerMedicamentos),
    map(mBuscarMedicamento)
  );

const solicitarPaciente = (action$, state$) =>
  action$.pipe(
    ofType(M_BUSCAR_PACIENTE),
    filter((action) => Resource.isQuery(action.payload)),
    map((action) => mBuscarPacienteRME(action.payload))
  );

const buscarPaciente = (action$, state$) =>
  action$.pipe(
    ofType(M_BUSCAR_PACIENTE_RME),
    toParams,
    authFromState(state$),
    makeRequestRaw(obtenerPaciente),
    flatMap((resource) => {
      const resource2 = resource.chain((response) => {
        if (response.value.status === 200) {
          return response.value
            .json()
            .then((data) => Resource.Data(data, response.params));
        }
        if (response.value.status === 204) {
          return Promise.resolve(Resource.Empty(response.params));
        }
        return Promise.resolve(Resource.Empty(response.params));
      });
      return from(resource2);
    }),
    map((resource) => resource.map(mapPaciente)),
    map(mBuscarPacienteRME)
  );

const buscarPacienteRMEEmpty = (action$, state$) =>
  action$.pipe(
    ofType(M_BUSCAR_PACIENTE_RME),
    filter((action) => Resource.isEmpty(action.payload)),
    map((action) =>
      mBuscarPacienteMDI(
        action.payload.update({
          [FIX_URL]: action.payload.params.nroDocumento,
        })
      )
    )
  );

const buscarPacienteRMEData = (action$, state$) =>
  action$.pipe(
    ofType(M_BUSCAR_PACIENTE_RME),
    filter((action) => Resource.isData(action.payload)),
    map((action) => mBuscarPaciente(action.payload))
  );

const buscarPacienteMDI = (action$, state$) =>
  action$.pipe(
    ofType(M_BUSCAR_PACIENTE_MDI),
    toParams,
    authFromState(state$),
    makeRequest(obtenerPacienteIdentificaciones),
    map((resource) =>
      resource
        .map(fromPolicia)
        .mapParams((params) => JSON.parse(JSON.stringify(params)))
    ),
    map(mBuscarPacienteMDI)
  );

const buscarPacienteMDIData = (action$, state$) =>
  action$.pipe(
    ofType(M_BUSCAR_PACIENTE_MDI),
    filter((action) => Resource.isData(action.payload)),
    map((action) => mBuscarPaciente(action.payload))
  );

const buscarPacienteMDIEmpty = (action$, state$) =>
  action$.pipe(
    ofType(M_BUSCAR_PACIENTE_MDI),
    filter((action) => Resource.isError(action.payload)),
    map(() =>
      error('Nro de Cédula no encontrada en la Base de Datos')
    )
  );

const triggerLimpiarFormulario = (action$, state$) =>
  action$.pipe(
    ofType(M_AGREGAR_RECETA),
    filter((action) => Resource.isData(action.payload)),
    mapTo(true),
    map(limpiarFormulario)
  );

const onRecetaEmitirSuccess = (action$, state$) =>
  action$.pipe(
    ofType(M_AGREGAR_RECETA),
    filter((action) => Resource.isData(action.payload)),
    flatMap((action) =>
      from([
        success('Receta emitida con éxito'),
        mListarPrescripciones(
          Resource.Query({
            id_persona: action.payload.params.userDTO.id,
          })
        ),
      ])
    )
  );

const onDuplicarReceta = (action$, state$) =>
  action$.pipe(
    ofType(M_DUPLICAR_PRESCRIPCION),
    filter((action) => Resource.isQuery(action.payload)),
    map((action) => ({
      type: action.type,
      payload: action.payload
        .empty()
        .mapEmptyParams(mapDuplicarPrescripcion)
        .query(),
    })),
    map((action) => mAgregarReceta(action.payload))
  );

const toastAddMedicamentosSuccess = (action$, state$) =>
  action$.pipe(
    ofType(M_SELECCIONAR_MEDICAMENTOS),
    map(() => success('Medicamento agregado exitosamente'))
  );

/// si es que existe el paciente se emite la receta y punto
const prepararPrescripcion = (action$, state$) =>
  action$.pipe(
    ofType(M_PRESCRIPCION_PREPARAR),
    withLatestFrom(state$),
    map(([action, state]) => {
      // TODO: arreglar query de prescripcion receta

      const sub = state.admin.tokens.tokenInfo
        .map((value) => value.parsed.id.sub)
        .getDataOr('');

      const pacienteId = state.admin.medico.paciente
        .map((value) => value.id)
        .getDataOr(state.admin.medico.paciente.params.id);

      const seleccion = state.admin.medico.seleccion;
      const indicaciones = state.admin.medico.indicaciones;
      const diagnostico = state.admin.medico.diagnostico;
      // TODO: ESTE EL OBJETO QUE SE VA
      const query = Resource.Query({
        activo: true,
        usuMod: sub,
        usuAlta: sub,
        userDTO: { id: sub },
        pacienteDTO: { id: pacienteId },
        fechAlta: new Date().getTime(),
        fechaPrescripcion: new Date().getTime(),
        diagnostico: diagnostico,
        indicaciones: indicaciones,
        prescripcionDetDTO: seleccion.map((medicamento) => ({
          medicamentoDTO: { id: medicamento.id },
          fechAlta: new Date().getTime(),
          fechMod: new Date().getTime(),
          usuMod: sub,
          usuAlta: sub,
          posologia: medicamento.posologia,
        })),
      });
      return mAgregarReceta(query);
    })
  );

const controlCrearPaciente = (action$, state$) =>
  action$.pipe(
    ofType(M_PRESCRIPCION_PRECHECK),
    map(() => mPrepararPaciente())
  );

const prepararePaciente = (action$, state$) =>
  action$.pipe(
    ofType(M_PREPARAR_PACIENTE),
    withLatestFrom(state$),
    map(([action, state]) => {
      const pacienteValue = state.admin.medico.paciente
        // TODO: ESTO TIENE QUE MAPEAR EL GENERO A COMO RECIBE EL BACKEND
        // male/female o si es m/f
        .map((x) => x)
        .getDataOr({});

      const datosContacto = state.admin.medico.datosContacto;
      const query = Resource.Query(
        merge(pacienteValue, datosContacto)
      );
      return mCrearPaciente(query);
    })
  );

// este requiere que al crear se devuelvan los datos creados
const crearPacientePrescripcion = (action$, state$) =>
  action$.pipe(
    ofType(M_CREAR_PACIENTE),
    toParams,
    authFromState(state$),
    makeRequest(crearPaciente),
    flatMap((resource) =>
      from([
        mBuscarPaciente(resource.map(mapPaciente)),
        mPrescripcionPreparar(),
      ])
    )
  );

export default combineEpics(
  solicitarPrescripciones,
  solicitarPaciente,
  buscarPaciente,
  buscarPacienteRMEEmpty,
  buscarPacienteRMEData,
  buscarPacienteMDI,
  buscarPacienteMDIData,
  buscarPacienteMDIEmpty,
  solicitarMedicamentos,
  guardarPrescripcion,
  triggerLimpiarFormulario,
  onRecetaEmitirSuccess,
  controlCrearPaciente,
  crearPacientePrescripcion,
  prepararPrescripcion,
  onDuplicarReceta,
  toastAddMedicamentosSuccess,
  prepararePaciente,
  solicitarVerificarCodigo,
  solicitarAceptarCondiciones,
  verificarCodigoError,
  verificarCodigoRefrescar,
  verificarCodigoExito
);

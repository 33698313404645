import React, { useState } from 'react';
import Header from './Header';
import useStyles from '../styles';
import Body from './Body';
import Buscador from './Body/Buscador';

export default () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className="p-3">
          <Body />
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { merge } from 'remeda';
import { useDispatch, useSelector } from 'react-redux';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import Form from './Form';
import Map from './Map';
import { crearSucursal } from '../../../redux/actions';

const initialState = {
  nombre: '',
  ci_responsable: '',
  responsable: '',
  position: {
    latitude: -24.52,
    longitude: -55.52,
  },
  ciudad: 'Asunción',
  direccion: '',
  telefono: '',
  email: '',
  id_organization: '',
};

export default () => {
  const agregando = useSelector(
    (state) => state.admin.sucursales.agregando
  );
  const dispatch = useDispatch();

  const [values, setValues] = useState(initialState);

  const setProperty = (name) => (e) => {
    const value = e.target.value;
    setValues((values) => merge(values, { [name]: value }));
  };

  const setPosition = (position) =>
    setValues((values) => merge(values, { position }));

  const organizationId = useSelector((state) =>
    state.admin.tokens.tokenInfo.map(
      (x) => x.parsed.id.id_healthcareService
    )
  );

  useEffect(() => {
    organizationId.matchWith({
      Data: ({ value }) => {
        setValues((values) =>
          merge(values, {
            id_organization: value,
          })
        );
      },
      Query: () => undefined,
      Empty: () => undefined,
      Error: () => undefined,
    });
  }, []);

  useEffect(
    () =>
      agregando.matchWith({
        Data: () => {
          setValues(initialState);
          dispatch(crearSucursal(Resource.Empty({})));
        },
        Query: () => undefined,
        Empty: () => undefined,
        Error: () => undefined,
      }),
    [agregando, dispatch]
  );

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(crearSucursal(agregando.update(values)));
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} xl={6} xs={12}>
        <Form values={values} setProperty={setProperty} />
      </Grid>
      <Grid item lg={6} md={6} xl={6} xs={12}>
        <Map
          position={values.position}
          setPosition={setPosition}
          onSubmit={onSubmit}
          agregando={agregando}
        />
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { Grid } from '@material-ui/core';
import Buscador from './Buscador';
import SeccionPaciente from './SeccionPaciente';
import SeccionMedicamentos from './SeccionMedicamentos';
import { useEffect } from 'react';
import { Farmacia } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

export default () => {
  const dispatch = useDispatch();

  const lista = useSelector((state) => state.admin.farmacias.lista);
  useEffect(() => {
    dispatch(Farmacia(lista.update()));
  }, []);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Buscador />
      </Grid>
      <Grid container>
        <div className="col-md-3">
          <SeccionPaciente />
        </div>

        <div className="p-3 col-md-9">
          <SeccionMedicamentos />
        </div>
      </Grid>
    </Grid>
  );
};

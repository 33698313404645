import { createBrowserHistory } from 'history';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  applyMiddleware,
  combineReducers,
  createStore,
  compose,
} from 'redux';

import {
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';

import admin from '../../main/redux/reducers/';
import epics from '../../main/redux/epics';
import main from './reducer';

import * as Actions from '../../main/redux/actionsTypes';

const composeEnhancers = composeWithDevTools({
  actionsBlacklist: [
    Actions.ALMACENAR_TOKENS,
    Actions.M_ACTUALIZAR_DIAGNOSTICO,
    Actions.M_ACTUALIZAR_INDICACION,
    Actions.M_ACTUALIZAR_POSOLOGIA,
  ],
});

// All reducer to combine
export const history = createBrowserHistory();
export default () => {
  const epicMiddleware = createEpicMiddleware();

  const middleware = [
    routerMiddleware(history), // for dispatching history actions
    epicMiddleware,
  ];
  const enhancer = composeEnhancers(
    applyMiddleware(...middleware)
    // other store enhancers if any
  );
  const reducer = combineReducers({
    main,
    admin,
    router: connectRouter(history),
  });
  const store = createStore(reducer, enhancer);
  epicMiddleware.run(epics);

  return store;
};

import { combineReducers } from 'redux';
import tokens from './tokens';
import sucursales from './sucursales';
import personas from './personas';
import paciente from './paciente';
import farmaceutico from './farmaceutico';
import medico from './medico';
import snackbar from './snackbar';
import farmacias from './farmacias';

export default combineReducers({
  snackbar,
  tokens,
  sucursales,
  personas,
  paciente,
  farmaceutico,
  medico,
  farmacias,
});

import { ofType, combineEpics } from 'redux-observable';
import { saveAs } from 'file-saver';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import {
  map,
  filter,
  flatMap,
  ignoreElements,
  tap,
} from 'rxjs/operators';
import { from } from 'rxjs';

import {
  toParams,
  makeRequest,
  makeBlobRequest,
} from 'ghost-stories/dist/streams/resource';

import {
  PACIENTE_SUCURSALES,
  PACIENTE_PRESCRIPCIONES,
  PACIENTE_PRESCRIPCION_DETALLE,
  PACIENTE_DESCARGAR_RECETA,
  PACIENTE_AGREGAR_RECETA,
} from '../actionsTypes';

import {
  actualizarPacienteSucursales,
  actualizarPacientePrescripciones,
  actualizarPacientePrescripcionDetalle,
  pacienteAgregarReceta,
  refrescarPantalla,
  success,
} from '../actions';

import { authFromState } from '../api/streams';

import {
  obtenerPacienteSucursales,
  obtenerPacientePrescripciones,
  obtenerPacientePrescriponesDetalles,
  obtenerReceta,
  guardarAgregarReceta,
} from '../api/index';
import { FIX_URL } from 'ghost-stories/dist/constants';

const solicitarSucursales = (action$, state$) =>
  action$.pipe(
    ofType(PACIENTE_SUCURSALES),
    toParams,
    authFromState(state$),
    makeRequest(obtenerPacienteSucursales),
    map(actualizarPacienteSucursales)
  );

const solicitarPrescripciones = (action$, state$) =>
  action$.pipe(
    ofType(PACIENTE_PRESCRIPCIONES),
    toParams,
    authFromState(state$),
    makeRequest(obtenerPacientePrescripciones),
    map(actualizarPacientePrescripciones)
  );

const solicitarPrescripcionesDetalles = (action$, state$) =>
  action$.pipe(
    ofType(PACIENTE_PRESCRIPCION_DETALLE),
    toParams,
    authFromState(state$),
    makeRequest(obtenerPacientePrescriponesDetalles),
    map(actualizarPacientePrescripcionDetalle)
  );

const relacionarReceta = (action$, state$) =>
  action$.pipe(
    ofType(PACIENTE_AGREGAR_RECETA),
    toParams,
    authFromState(state$),
    makeRequest(guardarAgregarReceta),
    map(pacienteAgregarReceta)
  );

const onRelacionarSuccess = (action$, state$) =>
  action$.pipe(
    ofType(PACIENTE_AGREGAR_RECETA),
    filter((action) => Resource.isData(action.payload)),
    flatMap(() =>
      from([
        success('Receta agregada con éxito'),
        refrescarPantalla(),
      ])
    )
  );

const guardarReceta = (action$, state$) =>
  action$.pipe(
    ofType(PACIENTE_DESCARGAR_RECETA),
    toParams,
    authFromState(state$),
    makeBlobRequest(obtenerReceta),
    tap((x) =>
      x.tap((data) => {
        const { blob, params } = data;
        const nombre = `Receta-${params[FIX_URL]}`;
        saveAs(blob, nombre, { type: 'application/pdf' });
      })
    ),
    ignoreElements()
  );

export default combineEpics(
  solicitarSucursales,
  solicitarPrescripciones,
  solicitarPrescripcionesDetalles,
  guardarReceta,
  relacionarReceta,
  onRelacionarSuccess
);

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ResourceRender from 'ghost-stories/dist/react/resource';
import { SucursalsToolbar, SucursalsTable } from './componentes';

const SucursalList = (props) => {
  const { classes } = props;
  const { sucursales } = useSelector((state) => ({
    sucursales: state.admin.sucursales.lista,
  }));
  const [filtro, setFiltro] = useState('');
  const [filtroValue, setFiltroValue] = useState('');
  const [soloHabilitada, setSoloHabilitada] = useState(true);

  return (
    <div className={classes.root}>
      <SucursalsToolbar
        filtro={filtro}
        setFiltro={setFiltro}
        filtroValue={filtroValue}
        setFiltroValue={setFiltroValue}
        soloHabilitada={soloHabilitada}
        setSoloHabilitada={setSoloHabilitada}
      />
      <div className={classes.content}>
        <ResourceRender
          resource={sucursales}
          props={{ filtro, filtroValue, soloHabilitada }}
          Data={SucursalsTable}
          Query={() => <div>query</div>}
          Empty={() => <div>Empty</div>}
          Error={() => <div>Error</div>}
        />
      </div>
    </div>
  );
};

export default SucursalList;

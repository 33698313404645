import { merge } from 'remeda';
import { MAIN_SELECT_PROFILE } from './actionTypes';
import PROFILES from '../../constants/profiles';
const initialState = {
  profile: PROFILES.mustChoose,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case MAIN_SELECT_PROFILE:
      return merge(state, { profile: action.payload });
    default:
      return state;
  }
};

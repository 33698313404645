import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';

export default (props) => {
  const lista = useSelector((state) => state.admin.farmacias.lista);
  const datos = useSelector(
    (state) => state.admin.tokens.userInfo
  ).getDataOr({
    name: 'sin nombre',
  });

  const farmacia = lista.value.find((x) => x.id === datos.id);

  return (
    <form>
      <Card>
        <CardHeader title="Datos de Contacto" />
        <Divider variant="middle" />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={9} xs={12}>
              <TextField
                fullWidth
                label="Teléfono Principal"
                name="telefonoPersonal"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                value={farmacia.telefono}
              />
            </Grid>
            <Grid item md={3} xs={12}></Grid>
            <Grid item md={9} xs={12}>
              <TextField
                fullWidth
                label="Correo Electrónico de la Red Farmaceutica"
                required
                type="email"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                value={farmacia.email}
              />
            </Grid>
            <Grid item md={3} xs={12}></Grid>
            <Grid item md={9} xs={12}>
              <TextField
                fullWidth
                label="Dirección"
                variant="outlined"
                value={farmacia.direccion}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}></Grid>
            <Grid item md={9} xs={12}>
              <TextField
                fullWidth
                label="Ciudad"
                variant="outlined"
                value={farmacia.ciudad}
                InputProps={{
                  readOnly: true,
                }}
              ></TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

import { ofType, combineEpics } from 'redux-observable';
import { map, filter } from 'rxjs/operators';
import { success } from '../actions';

import {
  toParams,
  makeRequest,
} from 'ghost-stories/dist/streams/resource';

import { Resource } from 'ghost-stories/dist/unions/Resource';

import {
  SUCURSALES_RESOURCE,
  CREAR_SUCURSAL,
  EDITAR_SUCURSAL,
} from '../actionsTypes';

import {
  actualizarSucursales,
  crearSucursal,
  editarSucursal,
} from '../actions';
import { authFromState } from '../api/streams';
import {
  obtenerSucursales,
  agregarSucursal,
  modificarSucursal,
} from '../api/index';

const procesarSucursales = (action$, state$) =>
  action$.pipe(
    ofType(SUCURSALES_RESOURCE),
    toParams,
    authFromState(state$),
    makeRequest(obtenerSucursales),
    map(actualizarSucursales)
  );

const postSucursal = (action$, state$) =>
  action$.pipe(
    ofType(CREAR_SUCURSAL),
    toParams,
    authFromState(state$),
    makeRequest(agregarSucursal),
    map(crearSucursal)
  );

const putSucursal = (action$, state$) =>
  action$.pipe(
    ofType(EDITAR_SUCURSAL),
    toParams,
    authFromState(state$),
    makeRequest(modificarSucursal),
    map(editarSucursal)
  );

const toastSucursal = (action$, state$) =>
  action$.pipe(
    ofType(CREAR_SUCURSAL, EDITAR_SUCURSAL),
    filter((action) => Resource.isData(action.payload)),
    map(() => success('La sucursal se ha guardado exitosamente'))
  );
export default combineEpics();
// procesarSucursales,
// postSucursal,
// putSucursal,
// toastSucursal

import React from 'react';
import { Grid } from '@material-ui/core';
import Formulario from './Formulario';

function DatosFarmacia() {
  return (
    <Grid container spacing={3}>
      <Grid item lg={1} xs={12}></Grid>
      <Grid item lg={10} xs={12}>
        <Formulario />
      </Grid>
      <Grid item lg={1} xs={12}></Grid>
    </Grid>
  );
}

export default DatosFarmacia;

import { Resource } from 'ghost-stories/dist/unions/Resource';
import { merge } from 'remeda';
import {
  EDITAR_USER_CONTACT,
  EDITAR_USER_PERSONAL,
  EDITAR_USER_CORPORATE,
  EDITAR_USER_SECURITY,
  EDITAR_USER_PROFILE,
  EDITAR_USER_PORTADA,
  PERSONA_RESOURCE,
  PERSONA_DIRECCION_REVERSE,
} from '../actionsTypes';
import {
  selectPersonales,
  selectContacto,
  selectCorporativo,
  addNombreCompleto,
} from '../state/personas';

import { datosToken } from '../state/tokens';

const initialState = {
  datos: Resource.Empty({}),
  security: Resource.Empty({
    currentPassword: '',
    newPassword: '',
    confirmation: '',
  }),
  profilePic: Resource.Empty({}),
  bannerPic: Resource.Empty({}),
  direccion: Resource.Empty({}),
  editando: {
    personales: Resource.Empty({
      prefijo: 'sr',
      nombre: '',
      apellido: '',
      nacionalidad: 'PY',
      tipoDocumento: 'cedula',
      documento: '',
      emisorDocumento: 'PY',
      fechaNacimiento: '2020-01-01',
      sexo: 'M',
      grupoSanguineo: 'o+',
    }),
    contacto: Resource.Empty({
      position: {
        latitude: -24.52,
        longitude: -55.52,
      },
      ciudad: 'Asuncion',
      departamento: '0',
      pais: 'PY',
      direccion: '',
      telefono1: '',
      telefono2: '',
      telefono1Type: 'HOME',
      telefono2Type: 'MOBILE',
      email1: '',
    }),
    corporativo: Resource.Empty({
      telefono3: '',
      email2: '',
      cargo: '',
    }),
    portada: Resource.Empty({}),
    perfil: Resource.Empty({}),
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDITAR_USER_CONTACT:
      return merge(state, {
        editando: merge(state.editando, {
          contacto: action.payload,
        }),
      });
    case EDITAR_USER_PERSONAL:
      return merge(state, {
        datos: action.payload.map(datosToken).map(addNombreCompleto),
        editando: merge(state.editando, {
          personales: action.payload,
        }),
      });
    case EDITAR_USER_CORPORATE:
      return merge(state, {
        editando: merge(state.editando, {
          corporativo: action.payload,
        }),
      });
    case EDITAR_USER_SECURITY:
      return merge(state, { security: action.payload });
    case EDITAR_USER_PROFILE:
      return merge(state, { profilePic: action.payload });
    case EDITAR_USER_PORTADA:
      return merge(state, { bannerPic: action.payload });
    case PERSONA_DIRECCION_REVERSE:
      return merge(state, { direccion: action.payload });
    case PERSONA_RESOURCE:
      return merge(state, {
        datos: action.payload.map(datosToken).map(addNombreCompleto),
        editando: merge(
          state.editando,
          action.payload.matchWith({
            Data({ value }) {
              return {
                // personales: Resource.Empty(selectPersonales(value)),
                contacto: Resource.Empty(selectContacto(value)),
                corporativo: Resource.Empty(
                  selectCorporativo(value)
                ),
              };
            },
            Query: () => ({}),
            Empty: () => ({}),
            Error: () => ({}),
          })
        ),
      });
    default:
      return state;
  }
};

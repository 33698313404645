import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import { omit } from 'remeda';
import Avatar from './Avatar';
import Form from './Form';
import Map from './Map';
import { useFormData } from '../../../helpers/formHelpers';
import { editarUsuarioContacto } from '../../../redux/actions';

export default () => {
  const dispatch = useDispatch();
  const { editando } = useSelector((state) => ({
    editando: state.admin.personas.editando.contacto,
  }));

  const [values, useProperty] = useFormData(
    omit(editando.params, ['position'])
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(editarUsuarioContacto(editando.update(values)));
    },
    [editando, values, dispatch]
  );
  const loading = Resource.isQuery(editando);

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <Avatar />
      </Grid>
      <Grid item lg={8} md={8} xl={8} xs={12}>
        <Form />
      </Grid>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Map {...{ onSubmit, loading }} />
      </Grid>
    </Grid>
  );
};

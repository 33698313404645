import React from 'react';
import clsx from 'clsx';
import {
  Typography,
  Breadcrumbs,
  Link,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
}));

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Breadcrumbs
        style={{ marginTop: '10px', marginBottom: '10px' }}
        aria-label="breadcrumb"
      >
        <Link color="inherit">Perfil</Link>
      </Breadcrumbs>
      <Typography variant="h3" color="textPrimary">
        Información
      </Typography>
    </div>
  );
}

export default Header;

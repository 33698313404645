// TODO: re factor a state/prescripcion
export const getPatientName = (value) =>
  value.resource.patient.name[0].text;
export const getDoctorName = (value) =>
  value.resource.person.name[0].text;
export const getDoctorRegister = (value) =>
  (
    value.resource.person.identifier.find(
      (x) => x.id === 'nro_registro'
    ) || { value: '' }
  ).value;

export const getPatientDocument = (value) =>
  (
    value.resource.patient.identifier.find(
      (x) => x.id === 'cedula'
    ) || { value: '' }
  ).value;

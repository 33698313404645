import {
  toParams,
  makeRequest,
} from 'ghost-stories/dist/streams/resource';
import { ofType, combineEpics } from 'redux-observable';
import { authFromState } from '../api/streams';
import { map } from 'rxjs/operators';
import { FARMACIAS } from '../actionsTypes';
import { obtenerFarmacias } from '../api';
import { Farmacia } from '../actions';

const solicitarFarmacias = (action$, state$) =>
  action$.pipe(
    ofType(FARMACIAS),
    toParams,
    authFromState(state$),
    makeRequest(obtenerFarmacias),
    map(Farmacia)
  );

export default combineEpics(solicitarFarmacias);

import React, { useCallback, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';
import { randomString, pick } from 'remeda';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  ListItem,
  Link,
  TextField,
  Badge,
  Fab,
  Toolbar,
} from '@material-ui/core';
import StoreIcon from '@material-ui/icons/Store';
import LockOutline from '@material-ui/icons/LockOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import MapIcon from '@material-ui/icons/Map';
import HistoryIcon from '@material-ui/icons/History';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';

import NavItem from './NavItem';
import { MakeOptions } from '../../helpers/formHelpers';

import { BASE_URL, PICTURE_ENDPOINT } from '../../../constants/vars';
import ROLES from '../../../constants/roles';
import ROUTES from '../../../constants/routes';
import { selectProfile } from '../../../shared/store/actions';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as FarmaLogo } from './farma-logo.svg';
import { limpiarFormulario } from '../../redux/actions';

const navConfig = [
  {
    items: [
      {
        title: 'Sucursales',
        icon: StoreIcon,
        roles: [ROLES.ADMIN_LOCALES],
        href: ROUTES.ADMIN_LOCALES,
      },
      {
        title: 'Paciente',
        icon: PersonIcon,
        roles: [ROLES.PACIENTE],
        href: ROUTES.PACIENTE,
      },
      {
        title: 'Recetas',
        icon: DescriptionIcon,
        roles: [ROLES.PACIENTE],
        href: ROUTES.PACIENTE_RECETAS,
      },
      {
        title: 'Historial de recetas',
        icon: HistoryIcon,
        roles: [ROLES.MEDICO],
        href: ROUTES.MEDICO,
      },
      {
        title: 'Dispensar',
        icon: LocalPharmacyIcon,
        roles: [ROLES.FARMACEUTICO],
        href: ROUTES.FARMACEUTICO_DISPENSAR,
      },
      {
        title: 'Mapa de Farmacias',
        icon: MapIcon,
        roles: [ROLES.ADMIN_LOCALES],
        href: ROUTES.UTILIDADES_MAPA_FARMACIAS,
      },
    ],
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 100,
    height: 100,
  },
}));

const PROFILE_NAMES = {
  [ROLES.ADMIN_LOCALES]: 'Administrador de  Locales',
  [ROLES.FARMACEUTICO]: 'Farmaceutico',
  [ROLES.MEDICO]: 'Médico',
  [ROLES.PACIENTE]: 'Paciente',
};

const mapRol = (keycloak) => {
  // const roles = Object.values(ROLES).filter((rol) =>
  //   keycloak.hasRealmRole(rol)
  // );
  const roles = [ROLES.FARMACEUTICO];
  // roles.push['Farmaceutico'];
  return pick(PROFILE_NAMES, roles);
};

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { keycloak } = useKeycloak();

  const user = useSelector((state) =>
    state.admin.tokens.tokenInfo
      .map((x) => x.parsed.id)
      .map(
        ({ family_name, given_name }) =>
          `${given_name} ${family_name}`
      )
  ).getDataOr('Usuario Sin Nombre');

  const userData = useSelector(
    (state) => state.admin.tokens.tokenInfo
  );

  const sucursal = useSelector((state) =>
    state.admin.tokens.userInfo.map((x) => x.name)
  ).getDataOr('Usuario sin nombre');

  const profile = useSelector((state) => state.main.profile);
  const dispatch = useDispatch();
  const userId = userData
    .map((x) => x.parsed.id.sub)
    .getDataOr('{ userId }');

  // const { imgKey, imgUrl } = useSelector((state) =>
  //   state.admin.personas.profilePic.getDataOr({
  //     imgKey: randomString(5),
  //     imgUrl: `${BASE_URL}/${PICTURE_ENDPOINT}/${userId}/perfil`,
  //   })
  // );

  // const content = (
  //   <div>
  //     <Box height="100%" display="flex" flexDirection="column">
  //       <PerfectScrollbar options={{ suppressScrollX: true }}>
  //         <Hidden lgUp>
  //           <Box p={2} display="flex" justifyContent="center">
  //             <RouterLink to="/"> </RouterLink>
  //           </Box>
  //         </Hidden>
  //         <Box p={2}>
  //           <Box display="flex" justifyContent="center">
  //             <Badge
  //               overlap="circle"
  //               anchorOrigin={{
  //                 vertical: 'bottom',
  //                 horizontal: 'right',
  //               }}
  //               // badgeContent={
  //               //   <RouterLink to="/perfil">
  //               //     <Fab
  //               //       size="small"
  //               //       color="primary"
  //               //       aria-label="edit"
  //               //     >
  //               //       <EditIcon />
  //               //     </Fab>
  //               //   </RouterLink>
  //               // }
  //             >
  //               <Avatar
  //                 className={classes.avatar}
  //                 alt="User"
  //                 // key={imgKey}
  //                 // src={imgUrl}
  //               />
  //             </Badge>
  //           </Box>
  //           <Box mt={2} textAlign="center">
  //             <Link
  //               variant="h5"
  //               color="textPrimary"
  //               underline="none"
  //             >
  //               {user}
  //             </Link>
  //           </Box>
  //           <Box mt={2} textAlign="center">
  //             <TextField
  //               fullWidth
  //               label="Perfil Actual"
  //               variant="outlined"
  //               value="Farmacia"
  //               InputProps={{
  //                 readOnly: true,
  //               }}
  //             >
  //               <MakeOptions options={mapRol(keycloak)} />
  //             </TextField>
  //           </Box>
  //         </Box>
  //         <Divider />
  //         <Box p={2}>
  //           {navConfig.map((config) => (
  //             <List key={uuid()}>
  //               {renderNavItems({
  //                 items: config.items.filter((x) =>
  //                   x.roles.some((x) => x === 'farmaceutico')
  //                 ),
  //                 pathname: '/farmaceutico',
  //               })}
  //             </List>
  //           ))}
  //         </Box>
  //       </PerfectScrollbar>
  //     </Box>
  //     <List
  //       style={{ bottom: 0, position: 'absolute', width: '100%' }}
  //     >
  //       <Divider />
  //       <ListItem button onClick={keycloak.logout}>
  //         <ListItemIcon>
  //           <LockOutline />
  //         </ListItemIcon>
  //         <ListItemText primary="Cerrar Sesión" />
  //       </ListItem>
  //     </List>
  //   </div>
  // );
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const goBack = useCallback((event) => {
    dispatch(limpiarFormulario());
  }, []);

  const drawer = (
    <div>
      <Box p={2}>
        <FarmaLogo />

        {/* {navConfig.map((config) => (
          <List style={{ marginTop: '10px' }} key={uuid()}>
            {renderNavItems({
              items: config.items.filter((x) =>
                x.roles.some((x) => x === 'farmaceutico')
              ),
              pathname: '/farmaceutico',
            })}
          </List>
        ))} */}

        <List style={{ marginTop: '10px' }} key={uuid()}>
          <NavItem
            depth={0}
            href="/farmaceutico"
            icon={LocalPharmacyIcon}
            // key={key}
            title="Dispensar"
            onClick={goBack}
          />
        </List>
      </Box>

      <List
        style={{ bottom: 0, position: 'absolute', width: '100%' }}
      >
        <Divider />
        <ListItem button onClick={keycloak.logout}>
          <ListItemIcon>
            <LockOutline />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesión" />
        </ListItem>
      </List>
    </div>
  );
  const drawerWidth = 240;
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          // classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          // classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

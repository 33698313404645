import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {},
  row: {
    flexGrow: 1,
  },
  media: {
    height: 140,
  },
  button: {
    color: 'white !important',
  },
  cover: {
    position: 'relative',
    height: 270,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:before': {
      position: 'absolute',
      content: '" "',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage:
        'linear-gradient(-180deg, rgba(0,0,0,0.00) 58%, rgba(0,0,0,0.32) 100%)',
    },
  },
}));

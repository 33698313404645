import { map } from 'remeda';
const PHONE_KEY = 'phone';
const EMAIL_KEY = 'email';

export const fromFHIR = (fhirValue) => {
  const direccion = fhirValue.address.line
    ? fhirValue.address.line.join('')
    : '';
  return {
    id: fhirValue.id,
    ciudad: fhirValue.address.city,
    direccion: direccion,
    responsable: fhirValue.responsable,
    ci_responsable: fhirValue.ci_responsable,
    nombre: fhirValue.name,
    active: fhirValue.active === true || fhirValue.active === 'true',
    telefono: fhirValue.telecom
      .filter((x) => x.system === PHONE_KEY)
      .map((x) => x.value)
      .join(','),
    email: fhirValue.telecom
      .filter((x) => x.system === EMAIL_KEY)
      .map((x) => x.value)
      .join(','),
    position: fhirValue.position,
    id_organization: fhirValue.providedBy.identifier,
    id_usuario: fhirValue.id_usuario,
  };
};

export const fromFHIRArray = map(fromFHIR);

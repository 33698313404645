import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ResourceRender from 'ghost-stories/dist/react/resource';
import { ReactComponent as BackIcon } from './back.svg';
import { ReactComponent as PatientIcon } from './patient.svg';
import CakeIcon from '@material-ui/icons/Cake';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { limpiarFormulario } from '../../../redux/actions';
import useWindowDimensions from '../../../helpers/useWindowDimensions';
const mapSexo = (gender) => {
  switch ((gender || 'male').trim().toLowerCase()) {
    case 'female':
      return 'Femenino';
    case 'f':
      return 'Femenino';
    case 'male':
      return 'Masculino';
    case 'm':
      return 'Masculino';
    default:
      return gender;
  }
};

const FichaPaciente = (props) => {
  const { value } = props;
  const dispatch = useDispatch();
  const goBack = useCallback((event) => {
    dispatch(limpiarFormulario());
  }, []);
  const { _, width: screenWidth } = useWindowDimensions();
  const [imgSize, setImgSize] = useState(200);
  useEffect(() => {
    if (screenWidth < 900) {
      setImgSize(120);
    } else if (screenWidth < 1350) {
      setImgSize(150);
    } else {
      setImgSize(200);
    }
  }, [screenWidth]);
  return (
    <Grid style={{ padding: '15px' }}>
      <IconButton
        onClick={goBack}
        item
        style={{ marginTop: '15px' }}
      >
        <BackIcon />
      </IconButton>
      <Avatar
        style={{
          marginTop: '20px',
          width: `${imgSize}px`,
          height: `${imgSize}px`,
          borderRadius: '10px',
        }}
        variant="square"
        src={value.photoUrl}
      >
        {/* <PatientIcon /> */}
      </Avatar>
      {/* <Grid item style={{ marginTop: '20px' }}>
        <PatientIcon />
      </Grid> */}

      <Grid item style={{ marginTop: '20px' }}>
        <Typography variant="body2" color="textSecondary">
          Paciente
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {value.nombre} {value.apellido}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          CI {value.nroDocumento}
        </Typography>
      </Grid>

      <Grid item style={{ marginTop: '20px' }}>
        <Typography variant="body2" color="textSecondary">
          Nacimiento
        </Typography>

        <Grid container>
          <Typography
            style={{ marginTop: '2px' }}
            variant="body1"
            color="textPrimary"
          >
            {value.fechaNac}
          </Typography>
          <CakeIcon style={{ fill: 'gray', marginLeft: '10px' }} />
        </Grid>
        <Typography variant="body1" color="textSecondary">
          {moment().diff(value.fechaNac, 'years')} años
        </Typography>
      </Grid>

      <Grid item style={{ marginTop: '20px' }}>
        <Typography variant="body2" color="textSecondary">
          Teléfono
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {value.telefono}
        </Typography>
      </Grid>

      <Grid item style={{ marginTop: '20px' }}>
        <Typography variant="body2" color="textSecondary">
          Email
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {value.email}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default () => {
  const paciente = useSelector(
    (state) => state.admin.farmaceutico.paciente
  );
  const medicamentos = useSelector(
    (state) => state.admin.farmaceutico.medicamentos
  );

  if (paciente.value === undefined) {
    return <Grid></Grid>;
  }
  return (
    <>
      <Card
        style={{
          backgroundColor: '#F4F5F7',
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
          height: '103vh',
        }}
      >
        <CardContent>
          <ResourceRender
            resource={paciente}
            Data={FichaPaciente}
            Error={() => ''}
            Empty={() => ''}
            Query={() => ''}
          />
        </CardContent>
      </Card>
    </>
  );
};

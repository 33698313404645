import {
  ALMACENAR_TOKENS,
  SUCURSALES_RESOURCE,
  USUARIO_RESOURCE,
  CREAR_SUCURSAL,
  EDITAR_SUCURSAL,
  EDITAR_USER_CONTACT,
  EDITAR_USER_PERSONAL,
  EDITAR_USER_CORPORATE,
  EDITAR_USER_SECURITY,
  EDITAR_USER_PROFILE,
  PERSONA_RESOURCE,
  PACIENTE_SUCURSALES,
  PACIENTE_PRESCRIPCION_DETALLE,
  PACIENTE_PRESCRIPCIONES,
  PACIENTE_DESCARGAR_RECETA,
  EDITAR_USER_PORTADA,
  PERSONA_DIRECCION_REVERSE,
  PACIENTE_AGREGAR_RECETA,
  F_BUSCAR_PACIENTE,
  F_BUSCAR_MEDICAMENTOS,
  F_PROCESAR_SELECCION,
  F_DISPENSAR_SELECCION,
  LIMPIAR_FORMULARIOS,
  M_LISTAR_PRESCRIPCIONES,
  M_BUSCAR_PACIENTE,
  M_BUSCAR_PACIENTE_RME,
  M_BUSCAR_PACIENTE_MDI,
  M_DATOS_CONTACTO,
  M_AGREGAR_RECETA,
  M_BUSCAR_MEDICAMENTOS,
  M_SELECCIONAR_MEDICAMENTOS,
  M_ACTUALIZAR_POSOLOGIA,
  M_ELIMINAR_POSOLOGIA,
  M_ACTUALIZAR_INDICACION,
  M_ACTUALIZAR_DIAGNOSTICO,
  MOSTRAR_MENSAJE,
  M_DUPLICAR_PRESCRIPCION,
  M_CREAR_PACIENTE,
  M_PRESCRIPCION_PRECHECK,
  M_PRESCRIPCION_PREPARAR,
  M_PREPARAR_PACIENTE,
  M_SOLICITAR_CODIGO,
  M_VERIFICAR_CODIGO,
  REFRESCAR_PANTALLA,
  FARMACIAS,
  LIMPIAR_PRESCRIPCIONES,
} from './actionsTypes';
const create = (type) => (payload) => ({ type, payload });
const empty = (type) => () => ({ type });

// export const cambiarGerente = create('ADMINISTRADOR/CAMBIAR');
export const almacenarToken = create(ALMACENAR_TOKENS);
export const actualizarSucursales = create(SUCURSALES_RESOURCE);
export const actualizarUsuario = create(USUARIO_RESOURCE);
export const crearSucursal = create(CREAR_SUCURSAL);
export const editarSucursal = create(EDITAR_SUCURSAL);

// PERSONA
export const editarUsuarioCorporate = create(EDITAR_USER_CORPORATE);
export const editarUsuarioContacto = create(EDITAR_USER_CONTACT);
export const editarUsuarioPersonal = create(EDITAR_USER_PERSONAL);
export const editarUsuarioSecurity = create(EDITAR_USER_SECURITY);
export const editarUsuarioProfile = create(EDITAR_USER_PROFILE);
export const editarUsuarioPortada = create(EDITAR_USER_PORTADA);
export const actualizarPersona = create(PERSONA_RESOURCE);
export const actualizarDireccion = create(PERSONA_DIRECCION_REVERSE);

// PACIENTE
export const actualizarPacienteSucursales = create(PACIENTE_SUCURSALES);
export const actualizarPacientePrescripcionDetalle = create(PACIENTE_PRESCRIPCION_DETALLE);
export const actualizarPacientePrescripciones = create(PACIENTE_PRESCRIPCIONES);
export const descargarReceta = create(PACIENTE_DESCARGAR_RECETA);
export const pacienteAgregarReceta = create(PACIENTE_AGREGAR_RECETA);

// FARMACEUTICO
export const fBuscarPaciente = create(F_BUSCAR_PACIENTE);
export const fBuscarMedicamentos = create(F_BUSCAR_MEDICAMENTOS);
export const procesarSeleccionDispensar = create(F_PROCESAR_SELECCION);
export const dispensarSeleccion = create(F_DISPENSAR_SELECCION);
export const limpiarFormulario = create(LIMPIAR_FORMULARIOS);
export const limpiarPrescripciones = create(LIMPIAR_PRESCRIPCIONES);

// MEDICO
export const mListarPrescripciones = create(M_LISTAR_PRESCRIPCIONES);
export const mBuscarPaciente = create(M_BUSCAR_PACIENTE);
export const mBuscarPacienteRME = create(M_BUSCAR_PACIENTE_RME);
export const mBuscarPacienteMDI = create(M_BUSCAR_PACIENTE_MDI);
export const mDatosContacto = create(M_DATOS_CONTACTO);
export const mAgregarReceta = create(M_AGREGAR_RECETA);
export const mBuscarMedicamento = create(M_BUSCAR_MEDICAMENTOS);
export const mSeleccionarMedicamento = create(M_SELECCIONAR_MEDICAMENTOS);
export const mActualizarPosologia = create(M_ACTUALIZAR_POSOLOGIA);
export const mEliminarPosologia = create(M_ELIMINAR_POSOLOGIA);
export const mActualizarIndicacion = create(M_ACTUALIZAR_INDICACION);
export const mActualizarDiagnostico = create(M_ACTUALIZAR_DIAGNOSTICO);
export const mDuplicarPrescripcion = create(M_DUPLICAR_PRESCRIPCION);
export const mPrescripcionPrecheck = empty(M_PRESCRIPCION_PRECHECK);
export const mPrescripcionPreparar = empty(M_PRESCRIPCION_PREPARAR);
export const mCrearPaciente = create(M_CREAR_PACIENTE);
export const mPrepararPaciente = empty(M_PREPARAR_PACIENTE);
export const mSolicitarCodigo = create(M_SOLICITAR_CODIGO);
export const mVerificarCodigo = create(M_VERIFICAR_CODIGO);
export const refrescarPantalla = empty(REFRESCAR_PANTALLA);

///FARMACIAS
export const Farmacia = create(FARMACIAS);

export const success = (message) => ({ type: MOSTRAR_MENSAJE, payload: { message, severity: 'success', open: true } });
export const error = (message) => ({ type: MOSTRAR_MENSAJE, payload: { message, severity: 'error', open: true } });
export const dismiss = () => ({ type: MOSTRAR_MENSAJE, payload: { message: '', severity: 'info', open: false } });

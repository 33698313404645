import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import SucursalRow from './SucursalRow';
import useStyles from './styles';

const SucursalsTable = (props) => {
  const { value, filtro, filtroValue, soloHabilitada } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const filtrarResultados = (sucursal) => {
    if (filtroValue === '') return true;
    if (filtro !== '') {
      return (sucursal[filtro] || '')
        .toLowerCase()
        .includes(filtroValue.toLowerCase());
    }
    return Object.values(sucursal)
      .filter((x) => !!x)
      .map((x) => x.toString())
      .join('')
      .toLowerCase()
      .includes(filtroValue.toLowerCase());
  };

  const filtrarHabilitadas = (sucursal) =>
    soloHabilitada === sucursal.active;

  const table = value
    .filter(filtrarHabilitadas)
    .filter(filtrarResultados);

  return (
    <Card className={clsx(classes.root)}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sucursal</TableCell>
                <TableCell>Responsable</TableCell>
                <TableCell>Correo</TableCell>
                <TableCell>Teléfono</TableCell>
                <TableCell>Ciudad</TableCell>
                <TableCell>Dirección</TableCell>
                <TableCell>Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table
                .slice(page * rowsPerPage, rowsPerPage * (page + 1))
                .map((sucursal) => (
                  <SucursalRow
                    key={sucursal.id}
                    sucursal={sucursal}
                    classes={classes}
                  />
                ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={table.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default SucursalsTable;

import {
  take,
  drop,
  flatten,
  findIndex,
  reject,
  omit,
} from 'remeda';
const mismoId = (t1) => (t2) => t1.id === t2.id;

const actualizarLista = (lista, elemento) => {
  const indice = findIndex(lista, mismoId(elemento));
  return flatten([
    take(lista, indice),
    [elemento],
    drop(lista, indice + 1),
  ]);
};

export const actualizarSeleccion = (lista, elemento) => {
  return actualizarLista(lista, elemento);
};

export const eliminarSeleccion = (lista, elemento) => {
  return reject(lista, mismoId(elemento));
};

export const mapDuplicarPrescripcion = (oldPrescripcion) => {
  const userDTO = { id: oldPrescripcion.usuAlta };
  const pacienteDTO = { id: oldPrescripcion.resource.patient.id };

  const fechasPrescripcion = {
    fechAlta: Date.now(),
    fechaPrescripcion: Date.now(),
  };

  const fechasPrescripcionDet = {
    fechAlta: 1604520731206,
    fechMod: 1604520731206,
  };

  const mainData = omit(oldPrescripcion, [
    'hashCalculate',
    'paciente',
    'cedula',
    'index',
    'id',
    'idPerson',
    'idHash',
    'fechaPrescripcion',
    'fechAlta',
    'fechMod',
    'ipEmision',
    'resource',
    'prescripcionDetDTO',
  ]);

  const mapDetalle = (detalle) => ({
    ...omit(detalle, [
      'id',
      'fechAlta',
      'fechMod',
      'medicamentoDTO',
      'prescripcionDTO',
    ]),
    ...fechasPrescripcionDet,
    medicamentoDTO: {
      id: detalle.medicamentoDTO.id,
    },
    dispensado: false,
  });

  const newPrescripcion = {
    userDTO,
    pacienteDTO,
    prescripcionDetDTO: oldPrescripcion.prescripcionDetDTO.map(
      mapDetalle
    ),
    ...mainData,
    ...fechasPrescripcion,
  };
  return newPrescripcion;
};

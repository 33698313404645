import { merge } from 'remeda';
import { Resource } from 'ghost-stories/dist/unions/Resource';

import {
  M_LISTAR_PRESCRIPCIONES,
  M_BUSCAR_PACIENTE,
  M_DATOS_CONTACTO,
  M_BUSCAR_MEDICAMENTOS,
  M_SELECCIONAR_MEDICAMENTOS,
  M_ACTUALIZAR_POSOLOGIA,
  LIMPIAR_FORMULARIOS,
  M_ELIMINAR_POSOLOGIA,
  M_ACTUALIZAR_INDICACION,
  M_ACTUALIZAR_DIAGNOSTICO,
} from '../actionsTypes';

import {
  actualizarSeleccion,
  eliminarSeleccion,
} from '../state/medico';

const initial = {
  prescripciones: Resource.Empty({ id_persona: '' }),
  paciente: Resource.Empty({ nroDocumento: '' }),
  prescripcion: Resource.Empty({}),
  datosContacto: { email: '', telefono: '' },
  indicaciones: '',
  diagnostico: '',
  medicamentos: Resource.Empty({
    nombreComercial: '',
    activo: true,
  }),
  seleccion: [],
};

export default (state = initial, action) => {
  switch (action.type) {
    case M_LISTAR_PRESCRIPCIONES:
      return merge(state, { prescripciones: action.payload });
    case M_BUSCAR_PACIENTE:
      return merge(state, {
        paciente: action.payload,
        datosContacto: action.payload
          .map((data) => ({
            email: data.email || '',
            telefono: data.telefono || '',
          }))
          .getDataOr({
            email: '',
            telefono: '',
          }),
      });
    case M_BUSCAR_MEDICAMENTOS:
      return merge(state, { medicamentos: action.payload });
    case M_SELECCIONAR_MEDICAMENTOS:
      return merge(state, {
        seleccion: state.seleccion.concat(action.payload),
      });
    case M_ACTUALIZAR_POSOLOGIA:
      return merge(state, {
        seleccion: actualizarSeleccion(
          state.seleccion,
          action.payload
        ),
      });
    case M_ACTUALIZAR_INDICACION:
      return merge(state, {
        indicaciones: action.payload,
      });
    case M_ACTUALIZAR_DIAGNOSTICO:
      return merge(state, {
        diagnostico: action.payload,
      });
    case M_ELIMINAR_POSOLOGIA:
      return merge(state, {
        seleccion: eliminarSeleccion(
          state.seleccion,
          action.payload
        ),
      });
    case M_DATOS_CONTACTO:
      return merge(state, {
        datosContacto: merge(state.datosContacto, action.payload),
      });
    case LIMPIAR_FORMULARIOS:
      return { ...initial, prescripciones: state.prescripciones };
    default:
      return state;
  }
};

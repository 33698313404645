import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ResourceRender from 'ghost-stories/dist/react/resource';
import { actualizarPacientePrescripciones } from '../../../redux/actions';

import Toolbar from './Toolbar';
import Recetas from './Recetas';
import { makeStyles } from '@material-ui/core';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export default (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const prescripciones = useSelector(
    (state) => state.admin.paciente.prescripciones
  );
  const persona = useSelector((state) => state.admin.personas.datos);

  useEffect(() => {
    if (Resource.isData(persona)) {
      const { value } = persona;
      const query = Resource.Query({
        nroCedula: value.documento,
        active: true,
      });
      dispatch(actualizarPacientePrescripciones(query));
    }
  }, []); // esto tiene que estar vacio para que se ejecute una sola vez

  const [filtro, setFiltro] = useState('');
  const [filtroValue, setFiltroValue] = useState('');
  const [soloHabilitada, setSoloHabilitada] = useState(true);

  return (
    <div className={classes.root}>
      <Toolbar
        filtro={filtro}
        setFiltro={setFiltro}
        filtroValue={filtroValue}
        setFiltroValue={setFiltroValue}
        soloHabilitada={soloHabilitada}
        setSoloHabilitada={setSoloHabilitada}
      />
      <div className={classes.content}>
        <ResourceRender
          resource={prescripciones}
          props={{ filtro, filtroValue, soloHabilitada }}
          Data={Recetas}
          Query={() => <Alert severity="info">Cargando...</Alert>}
          Empty={() => (
            <Alert severity="warning">
              No se obtuvieron resultados
            </Alert>
          )}
          Error={({ messages }) => (
            <div>Error: {messages.join('')}</div>
          )}
        />
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { merge } from 'remeda';
import { useDispatch, useSelector } from 'react-redux';
import { Resource } from 'ghost-stories/dist/unions/Resource';
import { useParams } from 'react-router-dom';
import Form from './Form';
import Map from './Map';
import { editarSucursal } from '../../../redux/actions';

const initialState = {
  nombre: '',
  ci_responsable: '',
  responsable: '',
  position: {
    logitude: -57.63539,
    latitude: -25.28207,
  },
  ciudad: 'Asunción',
  direccion: '',
  telefono: '',
  email: '',
  id_organization: '',
};

export default () => {
  const dispatch = useDispatch();

  const { sucursalId } = useParams();

  const editando = useSelector(
    (state) => state.admin.sucursales.editando
  );

  const sucursales = useSelector(
    (state) => state.admin.sucursales.lista
  );

  const organizationId = useSelector((state) =>
    state.admin.tokens.tokenInfo.map(
      (x) => x.parsed.id.id_healthcareService
    )
  );

  const sucursal = sucursales
    .map((value) => value.find((suc) => suc.id === sucursalId))
    .matchWith({
      Data: ({ value }) =>
        merge(initialState, {
          id: sucursalId,
          nombre: value.nombre,
          ci_responsable: value.ci_responsable,
          responsable: value.responsable,
          position: value.position,
          ciudad: value.ciudad,
          direccion: value.direccion,
          telefono: value.telefono,
          email: value.email,
          active: value.active,
          id_usuario: value.id_usuario,
        }),
      Query: () => initialState,
      Empty: () => initialState,
      Error: () => initialState,
    });

  const [values, setValues] = useState(sucursal);

  const setProperty = (name) => (e) =>
    setValues(merge(values, { [name]: e.target.value }));

  const setActive = () =>
    setValues(merge(values, { active: !values.active }));

  const setPosition = (position) =>
    setValues(merge(values, { position }));

  useEffect(
    () =>
      setValues((values) =>
        merge(values, {
          id_organization: organizationId,
        })
      ),
    []
  );

  useEffect(
    () =>
      editando.matchWith({
        Data: ({ value }) => {
          dispatch(editarSucursal(Resource.Empty({})));
        },
        Query: () => undefined,
        Empty: () => undefined,
        Error: () => undefined,
      }),
    [editando, dispatch]
  );

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(editarSucursal(editando.update(values)));
  };
  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} xl={6} xs={12}>
        <Form
          values={values}
          setProperty={setProperty}
          setActive={setActive}
        />
      </Grid>
      <Grid item lg={6} md={6} xl={6} xs={12}>
        <Map
          position={values.position}
          setPosition={setPosition}
          onSubmit={onSubmit}
          editando={editando}
        />
      </Grid>
    </Grid>
  );
};

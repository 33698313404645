import React from 'react';
import Grid from '@material-ui/core/Grid';

import SearchInput from './SearchInput';
import Switch from './Switch';
import FiltroSelect from './FiltroSelect';
// import { AgregarReceta } from './AgregarReceta';

export default (props) => {
  const {
    filtro,
    setFiltro,
    filtroValue,
    setFiltroValue,
    soloHabilitada,
    setSoloHabilitada,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={2}>
        <FiltroSelect filtro={filtro} setFiltro={setFiltro} />
      </Grid>
      <Grid item xs={4}>
        <SearchInput
          placeholder="Buscar"
          filtroValue={filtroValue}
          setFiltroValue={setFiltroValue}
        />
      </Grid>
      <Grid item xs={3}>
        <Switch
          soloHabilitada={soloHabilitada}
          setSoloHabilitada={setSoloHabilitada}
        />
      </Grid>
      {/* <Grid item xs={3}>
        <AgregarReceta />
      </Grid> */}
    </Grid>
  );
};

import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { TableCell, TableRow } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default (props) => {
  const { classes, sucursal } = props;
  return (
    <TableRow className={classes.tableRow} hover>
      <TableCell>
        <div className={classes.nameContainer}>
          <Typography>{sucursal.nombre}</Typography>
        </div>
      </TableCell>
      <TableCell>{sucursal.responsable}</TableCell>
      <TableCell>{sucursal.email}</TableCell>
      <TableCell>{sucursal.telefono}</TableCell>
      <TableCell>{sucursal.ciudad}</TableCell>
      <TableCell>{sucursal.direccion}</TableCell>
      <TableCell>
        <IconButton
          component={RouterLink}
          to={`/administrador/locales/editar/${sucursal.id}`}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const parse = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const tokenToUser = (tokens) => {
  const { idToken, refreshToken, token } = tokens;
  return {
    tokens,
    parsed: {
      id: parse(idToken),
      refresh: parse(refreshToken),
      current: parse(token),
    },
  };
};

export const datosToken = (farmacia) => {
  const name = farmacia.name;

  const defaultAddress = {
    city: 'Asuncion',
    line: [],
    country: 'PY',
  };

  const direccion = farmacia.address
    ? farmacia.address[0] || defaultAddress
    : defaultAddress;

  const telefono1 = farmacia.telecom.find(
    (x) => x.system === 'phone' && x.rank === 2
  ) || { value: '' };

  const email1 = farmacia.telecom.find(
    (x) => x.system === 'email' && x.rank === 1
  ) || { value: '' };

  return {
    id: farmacia.id,
    nombre: name,
    pais: direccion.country,
    direccion: direccion.line ? direccion.line.join('') : '',
    telefono1: telefono1.value,
    email: email1.value,
  };
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    backgroundColor: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

export default (props) => {
  const classes = useStyles();
  const { filtro, setFiltro } = props;
  const handleChange = (event) => {
    setFiltro(event.target.value);
  };

  return (
    <div>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        size="small"
        fullWidth
      >
        <InputLabel>Filtrar por</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={filtro}
          onChange={handleChange}
          label="Filtrar por"
        >
          <MenuItem value="">
            <em>Nada</em>
          </MenuItem>
          <MenuItem value="medico">Medico</MenuItem>
          <MenuItem value="id">Número</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

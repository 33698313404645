import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { randomString } from 'remeda';

import useStyles from './styles';
import SearchInput from './SearchInput';
import Switch from './Switch';
import FiltroSelect from './FiltroSelect';

import {
  BASE_URL,
  PICTURE_ENDPOINT,
} from '../../../../../constants/vars';

const SucursalsToolbar = (props) => {
  const {
    className,
    filtro,
    setFiltro,
    filtroValue,
    setFiltroValue,
    soloHabilitada,
    setSoloHabilitada,
    ...rest
  } = props;

  const classes = useStyles();
  const userData = useSelector(
    (state) => state.admin.tokens.tokenInfo
  );

  const userId = userData
    .map((x) => x.parsed.id.sub)
    .getDataOr('{ userId }');
  const { imgKey, imgUrl } = useSelector((state) =>
    state.admin.personas.profilePic
      .map((x) => ({
        imgKey: randomString(5),
        imgUrl: `${BASE_URL}/${PICTURE_ENDPOINT}/${userId}/portada`,
      }))
      .getDataOr({
        imgKey: randomString(5),
        imgUrl: `${BASE_URL}/${PICTURE_ENDPOINT}/${userId}/portada`,
      })
  );

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div
        key={imgKey}
        className={classes.cover}
        style={{ backgroundImage: `url(${imgUrl})` }}
      ></div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          to="/administrador/locales"
          component={RouterLink}
        >
          Sucursales
        </Link>
      </Breadcrumbs>
      <Typography variant="h6">Gestión de Cuentas</Typography>
      <Grid
        spacing={2}
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <Grid item md={3} xs={12}>
          <FiltroSelect filtro={filtro} setFiltro={setFiltro} />
        </Grid>

        <Grid item md={3} xs={12}>
          <SearchInput
            placeholder="Buscar"
            filtroValue={filtroValue}
            setFiltroValue={setFiltroValue}
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <Switch
            soloHabilitada={soloHabilitada}
            setSoloHabilitada={setSoloHabilitada}
          />
        </Grid>

        <Grid item md={2} xs={12}>
          <Button
            fullWidth
            className={classes.button}
            startIcon={<AddCircleOutlineIcon />}
            color="primary"
            variant="contained"
            href="#/administrador/locales/agregar"
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

SucursalsToolbar.propTypes = {
  className: PropTypes.string,
};

export default SucursalsToolbar;
